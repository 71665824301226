import React, { useEffect, useState } from "react";
import ImgLogo from "../utils/imagenes/LogoBugster.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function NavBar() {
  const [scrolling, setScrolling] = useState(false);

  const isAuthenticated = useSelector(state => state.isAuthenticated);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  return (
    <div
      className={` text-xs  sm:text-base fixed top-0 w-full p-2 pl-6 flex justify-between duration-500 ${
        scrolling
          ? "backdrop-blur-lg bg-white dark:bg-gray-700 opacity-90"
          : " backdrop-blur-lg "
      }`}
    >
      <div className="">
        <Link to="/">
          <img src={ImgLogo} alt="Company Logo" className=" w-20 h-20 " />
        </Link>
      </div>

      <div className=" my-auto mx-2">
        {isAuthenticated && (
          <Link to="/dashboard">
            <button className="bg-blue-800 w-32 mr-2 px-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit">
              Dashboard
            </button>
          </Link>
        )}
        {!isAuthenticated && (
          <>
            <Link to="/login">
              <button className="bg-blue-800 w-32 mr-2 px-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit">
                Log In
              </button>
            </Link>
            {/* <Link to="/singup">
              <button className="bg-blue-800 w-32 mr-2 px-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit">
                Sing Up
              </button>
            </Link> */}
          </>
        )}
      </div>
    </div>
  );
}

export default NavBar;
