import React from 'react';
import { Pie } from 'react-chartjs-2';
import 'chart.js/auto';

const PieChart = ({ chartData }) => {

  const options = {
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  }

  return (
    <div className=" h-max">
      <Pie data={chartData} options={options} />
    </div>
    )
};

export default PieChart;
