import React from "react";
import NavBar from "../components/NavBar";
import Footer from "./footer";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

function WelcomePage({ toggleDarkMode, darkMode }) {
  return (
    <div className="flex flex-col items-center justify-center w-screen min-h-screen bg-white dark:bg-gray-800">
      <NavBar toggleDarkMode={toggleDarkMode} darkMode={darkMode} />

      <div className="text-center mt-40 w-screen text-blue-800 dark:text-white">
        <h1 className="text-5xl font-mono font-bold">Welcome to Bugster!</h1>

        <div className=" w-full  flex justify-center items-center" style={{ height: '80vh' }}>
          <div className="w-4/5 h-5/6 aspect-w-16 aspect-h-9">
            <iframe
              src="https://www.youtube.com/embed/-ZvsGmYKhcU"
              title="Welcome Video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-full"
            ></iframe>
          </div>
        </div>

        <div className=" flex justify-center mb-28">
          <div className="text-lg w-3/4 text-gray-700 dark:text-gray-300">
            <h2 className="text-4xl text-left font-bold mb-6">
              Why Using Bugster for Code Test Automation is a Smart Move
            </h2>
            <p className="mb-4 text-left">
              In the realm of software development, quality and efficiency are
              two fundamental pillars that can determine the success or failure
              of a product. This is where Bugster, a code test automation
              software, shines as an essential tool for any development team.
              This article explores the key reasons why using Bugster can be a
              smart decision for enhancing software quality and streamlining the
              development process.
            </p>

            <h3 className=" text-2xl font-bold text-left mt-8">
              1. Enhanced Efficiency and Productivity
            </h3>
            <p className="mb-4 text-left">
              Bugster automates code testing, meaning developers and QA (Quality
              Assurance) personnel can spend less time on manual execution of
              repetitive tests and more time on tasks that require creative and
              analytical thinking. Automation allows multiple tests to run
              concurrently, significantly speeding up the validation process.
              This not only accelerates development cycles but also leads to a
              more robust and reliable product.
            </p>

            <h3 className=" text-2xl font-bold text-left mt-8">
              2. Improved Accuracy and Consistency
            </h3>
            <p className="mb-4 text-left">
              Manual testing is susceptible to human error, especially when
              dealing with complex or repetitive test scenarios. Bugster reduces
              this risk by consistently executing each test in the exact same
              manner every time. This consistency ensures that tests are not
              only accurate but also reliable, leading to software with fewer
              bugs and issues.
            </p>

            <h3 className=" text-2xl font-bold text-left mt-8">
              3. Early Bug Detection
            </h3>
            <p className="mb-4 text-left">
              With Bugster, testing can be integrated early and often in the
              development process. This approach, often referred to as
              "shift-left testing," allows teams to identify and address issues
              earlier, which is typically more cost-effective and less
              time-consuming than fixing bugs discovered later in the
              development cycle.
            </p>

            <h3 className=" text-2xl font-bold text-left mt-8">
              4. Comprehensive Test Coverage
            </h3>
            <p className="mb-4 text-left">
              Bugster enables teams to easily expand their test coverage.
              Automated tests can probe deeper into applications to test a wide
              array of scenarios and edge cases that might be overlooked in
              manual testing. This comprehensive coverage ensures that the
              application is thoroughly tested and capable of handling various
              user behaviors and conditions.
            </p>

            <h3 className=" text-2xl font-bold text-left mt-8">
              5. Continuous Integration and Development Support
            </h3>
            <p className="mb-4 text-left">
              Bugster is designed to seamlessly integrate with CI/CD pipelines,
              facilitating continuous integration and continuous development
              practices. This integration allows automated tests to be a part of
              the regular build process, ensuring immediate feedback on the
              impact of recent code changes, and contributing to the overall
              health of the software.
            </p>

            <h3 className=" text-2xl font-bold text-left mt-8">
              6. Scalability and Flexibility
            </h3>
            <p className="mb-4 text-left">
              As projects grow, Bugster scales with them. Its flexible framework
              can handle an increase in the number of tests or their complexity
              without a significant increase in resource allocation or execution
              time. This scalability is crucial for businesses that are growing
              and evolving rapidly.
            </p>

            <h3 className=" text-2xl font-bold text-left mt-8">
              7. Enhanced ROI
            </h3>
            <p className="mb-4 text-left">
              While there is an initial investment in setting up automated
              testing with Bugster, the return on investment over time is
              significant. Reduction in manual testing hours, decreased need for
              extensive QA phases, and early detection of bugs contribute to
              substantial cost savings and a more efficient development process.
            </p>

            <h3 className=" text-2xl font-bold text-left mt-8">Conclusion</h3>
            <p className="mb-4 text-left">
              Incorporating Bugster into your software development process can
              lead to substantial improvements in efficiency, accuracy, and
              overall software quality. Its ability to integrate with existing
              workflows, provide thorough test coverage, and support scalable
              projects makes it an invaluable asset for any development team
              aiming to produce high-quality software in a competitive market.
            </p>
          </div>
        </div>
      </div>

      <div className="text-center my-8">
        <h3 className="text-3xl font-bold mb-4 dark:text-white">Finished the tutorial?</h3>
        <Link
          to="/dashboard"
          className="text-sky-700 underline"
        >
          Start using Bugster   <FontAwesomeIcon className=" text-base" icon={faArrowRight} />
        </Link>
      </div>

      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
}

export default WelcomePage;
