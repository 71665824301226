import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTestCases } from "../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import PieChart from "./PieChart";
import axios from "axios";
import { GET_TEST_RUN_GROUPS } from "../../utils/URLs";
import StatusDropdown from "./StatusDropdown";

function TestRunDetail({ setTestRunDetailOpen, selectedRunGroupId }) {
  console.log(selectedRunGroupId);
  const dispatch = useDispatch();
  const [testCasesAfterRun, setTestCasesAfterRun] = useState([]);

  const handleTestRunGroups = async (id) => {
    try {
      const response = await axios.get(`${GET_TEST_RUN_GROUPS}${id}`);
      console.log(response.data.test_cases);
      setTestCasesAfterRun(response.data.test_cases);
      console.log(testCasesAfterRun);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    handleTestRunGroups(selectedRunGroupId);
  }, [selectedRunGroupId]);

  const videosUrls = [
    {
      title: "Navigation: Back to Results",
      url: "https://public-demo-bugster.s3.us-east-1.amazonaws.com/testNavigationBackToResults.mp4?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDYaCXNhLWVhc3QtMSJGMEQCICmRWc1pQHZ5hKyEs6CvGHNCgSbZ2258vNTl1Phw%2Fkv8AiAPNqCSB%2BKB%2BBHxgaQmBZjxS6Q39EQTWUdNjU3qh5BfdCrxAgi%2B%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDUyODgzODE1NzM0OCIMgX2NcLENa4foAjraKsUCPtIfgXTENyDTyUt7cK4DZogoy6MeRwNRrUDTwTHKc9HD3yuwXp5KE4c1u0ezdqst70FeXnplC2heJ2l1Lx0ZCvJfv%2FM4XHL0CAPmOJyuq%2Bt0XciZRIh9lx67ku95lrx%2Bj%2B9QRekAISduStrIWGTqGaeh%2FLJNRDUQIzX1nRG77LsLavFfe1xHiiP8vPk%2FG9UisZpTPPT0cQNnYviQkoqz3K6uUe0lk%2FUUo2ydFJYSzLlvMMsb%2BOWkTwkpEzPeE9b8cOuPQ6BvmGQQyUkZ8tBWXAYUxrWDqlUXgIjMAF7BXgyZ3kdpkFHgaagGmNQ13rQRpPYw8u5rSbWixiM7WTpS3UufGxymmxwihxSOh9c2y6GpK%2FEpXjx3dZbHWF5UHNiWL51V%2BaylVOxLdV%2ByEtvhr7XcnZzeY3T1NkbJXyOQMswVqLTIADCl7LWsBjq0Ake86HNqOXFhpeK3yRNgP2DTmTN2qgYn5uV6JJ%2BiS7k%2FBa5b%2FC1olRCzsDqFIawcaVRdCaZMdgmCKrJLtcJj9tjeGXzOr9PR4hIEUOVec4fHm0R4JLtIhposNqalG%2BmsTG6SV2gVfroO1V8WTPe8jqa9lY9cso98Q2JjPsIB165uaE0V0VblniQFmP8ohe0BojH3IBvSePO5cZfQKMN8EHWYF9ga3%2BIzCNgNGBbex3%2Bz93w2%2FnED10BED%2FkP%2FWvaz8K5Wvd93se%2Fo93zZLgj7Mi6wl2QqKTGpROOHQbIxAKoyQYKKb5OmqxPtiligXSTCwsu%2FE0ugCnLQAV%2F%2F2mpdUbhZyfTC8DGkKwp2daFcAklS4jqY%2FRInutWpIuLZmrCumK8q%2BpmQz2%2Fngd6RMcgRPABf%2BIi&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231228T132741Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAXWIJV4QSLQR5PMPN%2F20231228%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=e02dda177196204c8e53b59c038648a189d5da3daad92c89e9d03e173a6d8a38",
    },
    {
      title: "Search Results Relevance",
      url: "https://public-demo-bugster.s3.us-east-1.amazonaws.com/testSearchResultsRelevance.mp4?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDYaCXNhLWVhc3QtMSJGMEQCICmRWc1pQHZ5hKyEs6CvGHNCgSbZ2258vNTl1Phw%2Fkv8AiAPNqCSB%2BKB%2BBHxgaQmBZjxS6Q39EQTWUdNjU3qh5BfdCrxAgi%2B%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDUyODgzODE1NzM0OCIMgX2NcLENa4foAjraKsUCPtIfgXTENyDTyUt7cK4DZogoy6MeRwNRrUDTwTHKc9HD3yuwXp5KE4c1u0ezdqst70FeXnplC2heJ2l1Lx0ZCvJfv%2FM4XHL0CAPmOJyuq%2Bt0XciZRIh9lx67ku95lrx%2Bj%2B9QRekAISduStrIWGTqGaeh%2FLJNRDUQIzX1nRG77LsLavFfe1xHiiP8vPk%2FG9UisZpTPPT0cQNnYviQkoqz3K6uUe0lk%2FUUo2ydFJYSzLlvMMsb%2BOWkTwkpEzPeE9b8cOuPQ6BvmGQQyUkZ8tBWXAYUxrWDqlUXgIjMAF7BXgyZ3kdpkFHgaagGmNQ13rQRpPYw8u5rSbWixiM7WTpS3UufGxymmxwihxSOh9c2y6GpK%2FEpXjx3dZbHWF5UHNiWL51V%2BaylVOxLdV%2ByEtvhr7XcnZzeY3T1NkbJXyOQMswVqLTIADCl7LWsBjq0Ake86HNqOXFhpeK3yRNgP2DTmTN2qgYn5uV6JJ%2BiS7k%2FBa5b%2FC1olRCzsDqFIawcaVRdCaZMdgmCKrJLtcJj9tjeGXzOr9PR4hIEUOVec4fHm0R4JLtIhposNqalG%2BmsTG6SV2gVfroO1V8WTPe8jqa9lY9cso98Q2JjPsIB165uaE0V0VblniQFmP8ohe0BojH3IBvSePO5cZfQKMN8EHWYF9ga3%2BIzCNgNGBbex3%2Bz93w2%2FnED10BED%2FkP%2FWvaz8K5Wvd93se%2Fo93zZLgj7Mi6wl2QqKTGpROOHQbIxAKoyQYKKb5OmqxPtiligXSTCwsu%2FE0ugCnLQAV%2F%2F2mpdUbhZyfTC8DGkKwp2daFcAklS4jqY%2FRInutWpIuLZmrCumK8q%2BpmQz2%2Fngd6RMcgRPABf%2BIi&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231228T132821Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAXWIJV4QSLQR5PMPN%2F20231228%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=db5d11e98b930755400f2145dca98cb82a9ff169e7bbfbdf33efc0430ebb95ee",
    },
    {
      title: "Item Selection and Detail View",
      url: "https://public-demo-bugster.s3.us-east-1.amazonaws.com/testItemSelectionAndDetailView.mp4?response-content-disposition=inline&X-Amz-Security-Token=IQoJb3JpZ2luX2VjEDYaCXNhLWVhc3QtMSJGMEQCICmRWc1pQHZ5hKyEs6CvGHNCgSbZ2258vNTl1Phw%2Fkv8AiAPNqCSB%2BKB%2BBHxgaQmBZjxS6Q39EQTWUdNjU3qh5BfdCrxAgi%2B%2F%2F%2F%2F%2F%2F%2F%2F%2F%2F8BEAAaDDUyODgzODE1NzM0OCIMgX2NcLENa4foAjraKsUCPtIfgXTENyDTyUt7cK4DZogoy6MeRwNRrUDTwTHKc9HD3yuwXp5KE4c1u0ezdqst70FeXnplC2heJ2l1Lx0ZCvJfv%2FM4XHL0CAPmOJyuq%2Bt0XciZRIh9lx67ku95lrx%2Bj%2B9QRekAISduStrIWGTqGaeh%2FLJNRDUQIzX1nRG77LsLavFfe1xHiiP8vPk%2FG9UisZpTPPT0cQNnYviQkoqz3K6uUe0lk%2FUUo2ydFJYSzLlvMMsb%2BOWkTwkpEzPeE9b8cOuPQ6BvmGQQyUkZ8tBWXAYUxrWDqlUXgIjMAF7BXgyZ3kdpkFHgaagGmNQ13rQRpPYw8u5rSbWixiM7WTpS3UufGxymmxwihxSOh9c2y6GpK%2FEpXjx3dZbHWF5UHNiWL51V%2BaylVOxLdV%2ByEtvhr7XcnZzeY3T1NkbJXyOQMswVqLTIADCl7LWsBjq0Ake86HNqOXFhpeK3yRNgP2DTmTN2qgYn5uV6JJ%2BiS7k%2FBa5b%2FC1olRCzsDqFIawcaVRdCaZMdgmCKrJLtcJj9tjeGXzOr9PR4hIEUOVec4fHm0R4JLtIhposNqalG%2BmsTG6SV2gVfroO1V8WTPe8jqa9lY9cso98Q2JjPsIB165uaE0V0VblniQFmP8ohe0BojH3IBvSePO5cZfQKMN8EHWYF9ga3%2BIzCNgNGBbex3%2Bz93w2%2FnED10BED%2FkP%2FWvaz8K5Wvd93se%2Fo93zZLgj7Mi6wl2QqKTGpROOHQbIxAKoyQYKKb5OmqxPtiligXSTCwsu%2FE0ugCnLQAV%2F%2F2mpdUbhZyfTC8DGkKwp2daFcAklS4jqY%2FRInutWpIuLZmrCumK8q%2BpmQz2%2Fngd6RMcgRPABf%2BIi&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231228T132532Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=ASIAXWIJV4QSLQR5PMPN%2F20231228%2Fus-east-1%2Fs3%2Faws4_request&X-Amz-Signature=7c0da07bc74426416a2065806d8c2784f930cf565a49fdab2a51c1ca053c106b",
    },
  ];

  const getVideoUrlByTitle = (title) => {
    const video = videosUrls.find((video) => video.title === title);
    return video ? video.url : "#";
  };

  useEffect(() => {
    dispatch(getTestCases());
  }, [dispatch]);

  const handleTestRunDetailClose = () => {
    setTestRunDetailOpen(false);
  };

  const chartData = {
    labels: ["Passed"],
    datasets: [
      {
        label: "Test Status",
        data: [100],
        backgroundColor: ["rgba(75, 192, 192, 0.5)"],
      },
    ],
  };

  return (
    <div className="">
      <div className="flex flex-col items-center w-full">
        <button className="mb-4" onClick={handleTestRunDetailClose}>
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </button>

        <div className="flex flex-col md:flex-row w-full gap-4 mb-4">
          <div className="flex justify-center md:w-1/2 bg-white shadow-md rounded-xl h-72 dark:bg-gray-800 dark:text-white dark:border dark:border-gray-700">
            <PieChart chartData={chartData} />
          </div>
          <div className="flex items-center justify-center md:w-1/2 h-72 bg-white shadow-md rounded-xl dark:border dark:bg-gray-800 dark:text-white dark:border-gray-700">
            <div className="text-center">
              <div className="text-6xl font-semibold">100%</div>
              <div>passed</div>
              <div className="text-sm">410 / 961 untested (43%)</div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 h-full bg-white shadow-md rounded-xl dark:bg-gray-800 dark:text-white dark:border dark:border-gray-700">
        <table className="w-full">
          <thead className="">
            <tr className="border-b h-12 font-bold dark:border-gray-700">
              <th className="w-1/12 text-left p-2">ID</th>
              <th className="w-3/12 text-left p-2">Title</th>
              <th className="w-4/12 text-left p-2">Related Ticket</th>
              <th className="w-2/12 text-left p-2">Video</th>
              <th className="w-2/12 text-left p-2">Status</th>
            </tr>
          </thead>
          <tbody>
            {testCasesAfterRun.map((testCase) => (
              <tr
                key={testCase.test_case_id}
                className="h-14 border-b hover:bg-gray-200 dark:hover:bg-gray-700 dark:border-gray-700"
              >
                <td className="font-semibold text-xs p-2">
                  TC-{testCase.test_case_id}
                </td>
                <td className="p-2">
                  <p className="text-blue-500 underline cursor-pointer">
                    {testCase.test_title}
                  </p>
                </td>
                <td className="p-2">{testCase.ticket_id}</td>
                <td className="p-2 ml-1 text-blue-500 underline cursor-pointer">
                  <a
                    href={getVideoUrlByTitle(testCase.test_title)}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Link
                  </a>
                </td>
                <td className="p-2">
                  <StatusDropdown
                    currentStatus={testCase.status}
                    testCaseId={testCase.test_case_id}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TestRunDetail;
