import ImgLogo from "../utils/imagenes/LogoBugster.png";

function ContactUs() {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-blue-950 bg-opacity-100 z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-80 m-auto mt-32">
        <img src={ImgLogo} alt="Aryx Logo" className="w-20 mx-auto mb-4" />
        <form>
          <div className="mb-4">
            <input
              placeholder="Email"
              type="email"
              id="email"
              name="email"
              className="w-full p-2 mb-4 border rounded box-sizing focus:outline-none focus:border-blue-950"
            />
          </div>
          <div className="mb-4">
            <textarea
              placeholder="Subject"
              type="text"
              id="subject"
              name="subject"
              className="w-full p-2 h-5/6 mb-4 border rounded box-border focus:outline-none focus:border-blue-950"
            />
          </div>
          <button
            type="submit"
            className="w-full p-2 bg-blue-950 text-white rounded hover:bg-blue-800"
          >
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;