import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProjects } from "../../redux/actions";
import FilterProjects from "./FilterProjects";
import FormCreatProject from "./FormCreatProject";
import { FaSpinner } from "react-icons/fa";
import ProjectDetailModal from "./ProjectDetailModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import { DELETE_PROJECTS_URL } from "../../utils/URLs";

function ProjectsManagement() {
  const [idFilter, setIdFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [detailModal, setDetailModal] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [projectIdToDelete, setProjectIdToDelete] = useState(null);
  const [triggerReload, setTriggerReload] = useState(false);

  const projects = useSelector((state) => state.projects) || [];
  const dispatch = useDispatch();

  const updateProjectsList = () => {
    setTriggerReload(!triggerReload);
  };

  useEffect(() => {
    dispatch(getProjects()).then(() => {
      setIsLoading(false);
    });
  }, [dispatch, triggerReload]);

  const filteredProjects = projects.filter((project) => {
    const idMatch = project.id.toString().toLowerCase().includes(idFilter.toLowerCase());
    const nameMatch = project.name.toLowerCase().includes(nameFilter.toLowerCase());
    return idMatch && nameMatch;
  });

  const handleFormClick = () => {
    setIsFormModalOpen(true);
  };

  const handleProjectClick = (projectId) => {
    setSelectedProjectId(projectId);
    setDetailModal(true);
  };

  const handleDeleteProject = (projectId, event) => {
    event.stopPropagation();
    setProjectIdToDelete(projectId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setProjectIdToDelete(null);
  };

  const handleDeleteConfirm = () => {
    setIsDeleteModalOpen(false);
    axios.delete(`${DELETE_PROJECTS_URL}${projectIdToDelete}`)
      .then(response => {
        console.log("Project deleted successfully:", response);
        updateProjectsList();
      })
      .catch(error => {
        console.error("Error deleting the project:", error);
      });
    setProjectIdToDelete(null);
  };

  return (
    <div>
      <FilterProjects
        setIdFilter={setIdFilter}
        setNameFilter={setNameFilter}
        setIsFormModalOpen={setIsFormModalOpen}
      />

      {isLoading ? (
        <div className="flex justify-center items-center h-60">
          <FaSpinner className="animate-spin text-4xl text-blue-500" />
        </div>
      ) : filteredProjects.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 cursor-pointer">
          {filteredProjects.map((project) => (
            <div
              key={project.id}
              onClick={() => handleProjectClick(project.id)}
              className="group relative flex justify-center items-center h-60 bg-white shadow-md hover:shadow-lg rounded-xl p-4 dark:bg-inherit dark:text-white dark:border dark:border-gray-600 hover:bg-blue-100 dark:hover:bg-gray-600 transition duration-150 ease-in-out cursor-pointer"
            >
              <button
                onClick={(e) => handleDeleteProject(project.id, e)}
                className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-xs h-5 w-5 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 dark:bg-inherit"
              >
                <FontAwesomeIcon icon={faTimes} className="" />
              </button>
              <div>
                <p className="text-center">ID: {project.id}</p>
                <h3 className="text-3xl font-bold text-center">
                  {project.name}
                </h3>
                <div className="flex justify-center">
                  <a
                    href={project.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="text-blue-500 underline"
                  >
                    {project.url}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="text-center p-10">
          <p>There are no projects available.</p>
          <button
            onClick={handleFormClick}
            className="bg-blue-800 w-32 mt-4 px-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
          >
            Create Project
          </button>
        </div>
      )}

      {isFormModalOpen && (
        <FormCreatProject
          setIsOpen={setIsFormModalOpen}
          updateProjectsList={updateProjectsList}
        />
      )}

      {detailModal && (
        <ProjectDetailModal
          projectId={selectedProjectId}
          isOpen={detailModal}
          onClose={() => setDetailModal(false)}
        />
      )}

      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          text={<p>Are you sure you want to <strong>permanently delete</strong> this Project? <br></br> Please be aware that this action will also <strong>permanently delete the associated TestCases and Automated Code</strong>.</p>}

        />
      )}
    </div>
  );
}

export default ProjectsManagement;
