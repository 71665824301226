import { useState } from "react";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function ConnectToJiraModal({ onClose }) {
  const [apiKey, setApiKey] = useState("");
  const [email, setEmail] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    console.log("API Key:", apiKey);
    console.log("Email:", email);
    alert("In progress...")
    onClose(); // Close the modal after submission
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white border border-solid border-blue-950 h-2/4 w-3/4 md:h-2/4 md:w-2/4 rounded-xl p-8 relative dark:bg-gray-800 dark:border-gray-700 overflow-auto">
        <div className="flex justify-end absolute top-0 right-0 p-4">
          <button className="text-blue-950 dark:text-white" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <h2 className="text-blue-950 text-4xl font-bold mb-2 dark:text-white">
          Jira Information
        </h2>
        <form onSubmit={handleSubmit} className="mt-10">
          <div className="mb-4">
            <label
              className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
              htmlFor="apiKey"
            >
              API Key
            </label>
            <input
              className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
              type="text"
              id="apiKey"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              required
            />
          </div>

          <div className="mb-6">
            <label
              className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
        </form>
        <button
              type="submit"
              className="absolute bottom-5 right-5 bg-blue-800 text-sm text-white border border-blue-800 py-2 px-4 rounded flex items-center justify-center cursor-pointer hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 dark:hover:bg-inherit"
            >
              Connect
            </button>
      </div>
    </div>
  );
}

export default ConnectToJiraModal;
