import React from 'react';
import ImgLogo from "../utils/imagenes/LogoBugster.png";

function ErrorPage() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-blue-950 bg-opacity-100">
      {/* Logo centrado */}
      <div className="flex flex-col items-center justify-center">
        <img src={ImgLogo} alt="Bugster Logo" className="w-48 h-48" />
        <div className="text-center mt-6 text-white">
          <h1 className="text-2xl font-mono font-bold">404 |</h1>
          <p className="mt-2">
            This page could not be found.
          </p>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
