import { useState } from "react";
import ImgLogo from "../utils/imagenes/LogoBugster.png"

const LoginPopup = ({ setAuthenticated, authenticated }) => {
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const [error, setError] = useState("");

  const handleLogin = () => {
    if (
      credentials.username === "demo" &&
      credentials.password === "password"
    ) {
      setAuthenticated(true);
      setError("");
    } else {
      setError("Invalid credentials");
    }
  };

  return (
    <div
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-blue-950 bg-opacity-100 z-50 dark:bg-gray-800 dark:border-gray-700"
      style={{ display: authenticated ? "none" : "block" }}
    >
      <div className="bg-white p-6 rounded-lg shadow-lg w-80 m-auto mt-32 dark:bg-gray-800 dark:border dark:border-gray-700">
        <img
          src={ImgLogo}
          alt="Aryx Logo"
          className="w-20 mx-auto mb-4"
        />
        {error && (
          <div className="text-red-600 mb-2 text-center">{error}</div>
        )}
        <input
          placeholder="Username"
          className="w-full p-2 mb-4 border rounded box-sizing focus:outline-none focus:border-blue-950 dark:bg-gray-800 dark:border-gray-700"
          onChange={(e) =>
            setCredentials({ ...credentials, username: e.target.value })
          }
        />
        <input
          placeholder="Password"
          type="password"
          className="w-full p-2 mb-4 border rounded box-border focus:outline-none focus:border-blue-950 dark:bg-gray-800 dark:border-gray-700"
          onChange={(e) =>
            setCredentials({ ...credentials, password: e.target.value })
          }
        />
        <button
          className="w-full p-2 bg-blue-800 text-white rounded border border-blue-800 hover:bg-inherit hover:border hover:border-blue-800 hover:text-blue-800"
          onClick={handleLogin}
        >
          Login
        </button>
      </div>
    </div>
  );
};

export default LoginPopup;
