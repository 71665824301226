import axios from "axios";
import { GET_TESTCASES_URL, GET_TICKETS_URL, GET_CODEFILES_URL, GET_RUN_GROUPS_URL, GET_PROJECTS_URL} from "../utils/URLs";
import { auth } from "../Firebase/firebase";

export const GET_TESTCASES = "GET_TESTCASES";
export const GET_TICKETS = "GET_TICKETS";
//ACTIONS FILTER TEST CASES
export const FILTER_BY_TICKET = "FILTER_BY_TICKET";
export const FILTER_BY_AUTOMATION_STATUS = "FILTER_BY_AUTOMATION_STATUS";
export const SEARCH_BY_NAME = "SEARCH_BY_NAME";
//ACTIONS FITLER TICKETS
export const SEARCH_TICKET_BY_SUMMARY = "SEARCH_TICKET_BY_SUMMARY";
export const FILTER_TICKET_BY_ISSUE_TYPE = "FILTER_TICKET_BY_ISSUE_TYPE";
export const FILTER_TICKET_BY_PERSON = "FILTER_TICKET_BY_PERSON";
//AUTOMATE TCs
export const GET_CODE_FILES = "GET_CODE_FILES";
export const POST_AUTOMATETC = "POST_AUTOMATETC";
//RunsGroup
export const GET_RUN_GROUPS = "GET_RUN_GROUPS"
// authActions.js
export const SET_CURRENT_USER = 'SET_CURRENT_USER';
export const LOGOUT = 'LOGOUT';
//Projects
export const GET_PROJECTS = "GET_PROJECTS";

export const getTestCases = () => {
  const URL = GET_TESTCASES_URL;

  return async (dispatch) => {
    try {
      const { data } = await axios.get(URL);
      return dispatch({
        type: GET_TESTCASES,
        payload: data,
      });
    } catch (error) {
      console.error("Error en la solicitud Axios:", error);

      throw error;
    }
  };
};

export const getTickets = () => {
  const endpoint = GET_TICKETS_URL; //el 1 tendria que ser dinamico

  return async (dispatch) => {
    try {
      const { data } = await axios.get(endpoint);
      return dispatch({
        type: GET_TICKETS,
        payload: data,
      });
    } catch (error) {
      console.error("Error en la solicitud Axios:", error);

      throw error;
    }
  };
};

//PRUEBA ESTADO GLOBAL AUTOMATE TCs
export const getCodeFiles = () => {
  return async (dispatch) => {
    try {
      const { data } = await axios.get(GET_CODEFILES_URL)
      console.log(data);
      dispatch({
        type: GET_CODE_FILES,
        payload: data,
      });
    } catch (error) {
      console.error("Error en la solicitud Axios:", error);
      throw error;
    }
  };
};


export const postAutomateTC = (newAutomateTC) => {
  return {
    type: POST_AUTOMATETC,
    payload: newAutomateTC
  }
}

// TEST CASES FILTERS
export const filterByTicket = (selectedTicket) => {
  return {
    type: FILTER_BY_TICKET,
    payload: selectedTicket,
  };
};

export const filterByAutomationStatus = (automationStatus) => {
  return {
    type: FILTER_BY_AUTOMATION_STATUS,
    payload: automationStatus,
  };
};

export const searchByName = (searchTerm) => {
  return {
    type: SEARCH_BY_NAME,
    payload: searchTerm,
  };
};

//TICKETS FILTERS
export const searchTicketBySummary = (searchTermSummary) => {
  return {
    type: SEARCH_TICKET_BY_SUMMARY,
    payload: searchTermSummary,
  };
};

export const filterByIssueType = (selectedIssueType) => {
  return {
    type: FILTER_TICKET_BY_ISSUE_TYPE,
    payload: selectedIssueType,
  };
};

export const filterByPerson = (selectedPerson) => {
  return {
    type: FILTER_TICKET_BY_PERSON,
    payload: selectedPerson,
  };
};

//RUN GROUP
export const getRunGroups = () => {
  const URL = GET_RUN_GROUPS_URL;

  return async (dispatch) => {
    try {
      const { data } = await axios.get(URL);
      console.log(data);
      return dispatch({
        type: GET_RUN_GROUPS,
        payload: data,
      });
    } catch (error) {
      console.error("Error en la solicitud Axios de Get Run Group:", error);

      throw error;
    }
  };
};

// authActions.js
export const setCurrentUser = (user) => ({
  type: SET_CURRENT_USER,
  payload: user
});

// export const logout = () => ({
//   type: LOGOUT
// });
export const logout = () => {
  return (dispatch) => {
    auth.signOut().then(() => {
      // Clear user data from LocalStorage
      localStorage.removeItem('user');

      // Dispatch logout action to Redux store
      dispatch({ type: LOGOUT });

      // Additional logic if necessary
    }).catch((error) => {
      // Error handling
      console.error("Error al cerrar sesión:", error);
    });
  };
};

//Projects 
export const getProjects = () => {

  return async (dispatch) => {
    try {
      const response = await axios.get(GET_PROJECTS_URL);
      const data = response.data;

      return dispatch({
        type: GET_PROJECTS,
        payload: data,
      });
    } catch (error) {
      console.error("Error en la solicitud Axios:", error);
      throw error;
    }
  };
};