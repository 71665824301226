import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faListCheck,
  faPaste,
  faCode,
  faEarthAmericas,
  faGear,
  faDiagramProject
} from "@fortawesome/free-solid-svg-icons";
import ImgLogo from "../utils/imagenes/LogoBugster.png";
import { Link } from "react-router-dom";

function Sidebar({ setSelectedOption, selected }) {
  const iconComponents = [
    <FontAwesomeIcon icon={faDiagramProject} className="text-2xl" />,
    <FontAwesomeIcon icon={faListCheck} className="text-2xl" />,
    <FontAwesomeIcon icon={faPaste} className="text-2xl" />,
    <FontAwesomeIcon icon={faCode} className="text-xl" />,
    <FontAwesomeIcon icon={faEarthAmericas} className="text-2xl" />,
    <FontAwesomeIcon icon={faGear} className="text-2xl" />,
  ];

  const handleOptionClick = (text) => {
    setSelectedOption(text);
  };

  return (
    <div className="flex flex-col md:flex-row bg-gray-100 md:bg-inherit h-full text-blue-950 dark:bg-inherit dark:text-white w-screen md:w-auto py-4">
      {/* Sidebar para Escritorio */}
      <div className="pt-4 mr-4 w-48 hidden md:block">
        <div className="px-10 pb-4">
          <Link to="/">
            <img src={ImgLogo} alt="Company Logo" className="w-20 h-20" />
          </Link>
        </div>

        {[
          "Projects",
          "Tasks",
          "TestCase Management",
          "Test Automation",
          "Runs and Results",
          "Settings",
        ].map((text, index) => (
          <div
            key={text}
            onClick={() => handleOptionClick(text)}
            className={`p-4 py-6 cursor-pointer flex items-center text-base ${
              selected === text
                ? "bg-gray-200 dark:bg-gray-700 rounded-r-full"
                : "hover:bg-gray-200 hover:rounded-r-full dark:hover:bg-gray-700"
            }`}
          >
            <div>{iconComponents[index]}</div>
            <p className="pl-3 w-20">{text}</p>
          </div>
        ))}
      </div>

      {/* Sidebar para Móviles */}
      <div className="md:hidden flex items-center justify-center text-white w-screen">
        <div className="flex">
          {[
            "Projects",
            "Tasks",
            "TestCase Management",
            "Test Automation",
            "Runs and Results",
            "Settings",
          ].map((text, index) => (
            <div
              key={text}
              onClick={() => handleOptionClick(text)}
              className={`mx-1 p-2 cursor-pointer ${
                selected === text ? "bg-gray-700 rounded-md" : "hover:bg-gray-700 hover:rounded-md"
              }`}
            >
              {iconComponents[index]}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
