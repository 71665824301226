import {
  GET_TESTCASES,
  GET_TICKETS,
  GET_CODE_FILES,//////////////
  FILTER_BY_TICKET,
  FILTER_BY_AUTOMATION_STATUS,
  SEARCH_BY_NAME,
  SEARCH_TICKET_BY_SUMMARY,
  FILTER_TICKET_BY_ISSUE_TYPE,
  FILTER_TICKET_BY_PERSON,
  POST_AUTOMATETC,
  GET_RUN_GROUPS,
  SET_CURRENT_USER,
  LOGOUT,
  GET_PROJECTS
} from "./actions";

let initialState = {
  testCases: [],
  tickets: [],
  filteredTestCases: [],
  filteredTickets: [],
  selectedTicket: "All",
  selectedAutomationStatus: "All",
  selectedIssueType: "All",
  selectedPerson: "All",
  codeFiles: [],
  runGroups:[],
  currentUser: null,
  isAuthenticated: false,
  projects: [],
};


const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_TESTCASES:
      return {
        ...state,
        testCases: action.payload,
        filteredTestCases: action.payload,
      };

    case GET_TICKETS:
      return {
        ...state,
        tickets: action.payload.issues, // = a tickets.issues
        filteredTickets: action.payload.issues,
      };

    case GET_CODE_FILES://PRUEBA
      return {
        ...state,
        codeFiles: action.payload
      }

    case POST_AUTOMATETC:
      return {
        ...state,
        automateTCs: [...state.automateTCs, action.payload],
      };

    case FILTER_BY_TICKET:
      const selectedTicket = action.payload;
      const filteredTestCasesByTicket =
        selectedTicket === "All"
          ? state.testCases
          : state.testCases.filter(
              (testCase) => testCase.ticket_id == selectedTicket //Cambiar a === si los datos vienen iguales iguales iguales
            );

      const filteredTestCasesConvinados1 = applyAutomationStatusFilter(
        filteredTestCasesByTicket,
        state.selectedAutomationStatus
      );

      return {
        ...state,
        filteredTestCases: filteredTestCasesConvinados1,
        selectedTicket,
      };

    case FILTER_BY_AUTOMATION_STATUS:
      const selectedAutomationStatus = action.payload;
      let filteredTestCasesByAutomationStatus;
      if (selectedAutomationStatus === "All") {
        filteredTestCasesByAutomationStatus = state.testCases;
      } else if (selectedAutomationStatus === "false") {
        filteredTestCasesByAutomationStatus = state.testCases.filter(
          (testCase) => testCase.is_automated === false
        );
      } else if (selectedAutomationStatus === "true") {
        filteredTestCasesByAutomationStatus = state.testCases.filter(
          (testCase) => testCase.is_automated === true
        );
      }

      const filteredTestCasesConvinados2 = applyTicketFilter(
        filteredTestCasesByAutomationStatus,
        state.selectedTicket
      );

      return {
        ...state,
        filteredTestCases: filteredTestCasesConvinados2,
        selectedAutomationStatus,
      };

    case SEARCH_BY_NAME:
      const searchTerm = action.payload;
      const filteredTestCasesByName = state.testCases.filter((testCase) =>
        testCase.test_title.toLowerCase().includes(searchTerm.toLowerCase())
      );

      return {
        ...state,
        filteredTestCases: filteredTestCasesByName,
        searchTerm,
      };

    //TICKETS FILTERS
    case SEARCH_TICKET_BY_SUMMARY:
      const searchTermSummary = action.payload;
      const filteredTicketsBySummary = state.tickets.filter((ticket) =>
        ticket.fields.summary
          .toLowerCase()
          .includes(searchTermSummary.toLowerCase())
      );

      return {
        ...state,
        filteredTickets: filteredTicketsBySummary,
      };

    case FILTER_TICKET_BY_ISSUE_TYPE:
      const selectedIssueType = action.payload;
      const filteredTicketsByIssueType =
        selectedIssueType === "All"
          ? state.tickets
          : state.tickets.filter(
              (ticket) => ticket.fields.issuetype.name === selectedIssueType
            );

      const filteredTicketsCombined1 = applyPersonFilter(
        filteredTicketsByIssueType,
        state.selectedPerson
      );

      return {
        ...state,
        filteredTickets: filteredTicketsCombined1,
        selectedIssueType,
      };

    case FILTER_TICKET_BY_PERSON:
      const selectedPerson = action.payload;
      let filteredTicketsByPerson;
      if (selectedPerson === "All") {
        filteredTicketsByPerson = state.tickets;
      } else if (selectedPerson === "Unassigned") {
        filteredTicketsByPerson = state.tickets.filter(
          (ticket) => ticket.fields.assignee === null
        );
      } else {
        filteredTicketsByPerson = state.tickets.filter(
          (ticket) => ticket.fields.assignee?.displayName === selectedPerson
        );
      }

      const filteredTicketsCombined2 = applyIssueTypeFilter(
        filteredTicketsByPerson,
        state.selectedIssueType
      );

      return {
        ...state,
        filteredTickets: filteredTicketsCombined2,
        selectedPerson,
      };

    case GET_RUN_GROUPS:
        return {
          ...state,
          runGroups: action.payload,
        };

    //Auth
    case SET_CURRENT_USER:
      return {
        ...state,
        currentUser: action.payload,
        isAuthenticated: true
      };
    // case LOGOUT:
    //   return {
    //     ...state,
    //     currentUser: null,
    //     isAuthenticated: false
    //   };

    case GET_PROJECTS:
      return {
        ...state,
        projects: action.payload,
      };

    default:
      return state;
  }
};

export default rootReducer;




// FUNCIONES PARA CONVINAR LOS FILTROS DE TEST CASES
const applyAutomationStatusFilter = (testCases, automationStatus) => {
  return automationStatus === "All"
    ? testCases
    : testCases.filter(
        (testCase) => testCase.is_automated === (automationStatus === "true")
      );
};

const applyTicketFilter = (testCases, selectedTicket) => {
  return selectedTicket === "All"
    ? testCases
    : testCases.filter((testCase) => testCase.ticket_id === selectedTicket);
};

// FUNCIONES PARA CONVINAR LOS FILTROS DE TICKETS
const applyPersonFilter = (tickets, person) => {
  return person === "All"
    ? tickets
    : tickets.filter(
        (ticket) => ticket.fields.assignee?.displayName === person
      );
};

const applyIssueTypeFilter = (tickets, issueType) => {
  return issueType === "All"
    ? tickets
    : tickets.filter((ticket) => ticket.fields.issuetype.name === issueType);
};
