import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getRunGroups } from "../../redux/actions";
import { FaSpinner } from "react-icons/fa";
import FilterRunsAndResults from "./FilterRunsAndResults";

function TableRunsAndResults({ setTestRunDetailOpen, setSelectedRunGroupId }) {
  const [isLoading, setIsLoading] = useState(true);

  // Estados para los filtros
  const [idFilter, setIdFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  const runGroups = useSelector((state) => state.runGroups);
  const dispatch = useDispatch();

  useEffect(() => {
    if (runGroups.length === 0) {
      setIsLoading(true);
      dispatch(getRunGroups()).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [dispatch, runGroups.length]);

  const runTestGroupOrchestrator = (groupId) => {
    alert(`Group ${groupId} run  successfully`);
  };

  const handleOpenDetail = (groupId) => {
    setTestRunDetailOpen(true);
    setSelectedRunGroupId(groupId);
  };

  const updateIdFilter = (newId) => {
    setIdFilter(newId);
  };

  const updateNameFilter = (newName) => {
    setNameFilter(newName);
  };

  const filteredRunGroups = runGroups.filter((group) => {
    const idStr = String(group.id).toLowerCase();
    const nameStr = group.name.toLowerCase();
  
    return idStr.includes(idFilter.toLowerCase()) && nameStr.includes(nameFilter.toLowerCase());
  });

  return (
    <div>
      <FilterRunsAndResults
        updateIdFilter={updateIdFilter}
        updateNameFilter={updateNameFilter}
      ></FilterRunsAndResults>
      <div className="flex flex-col gap-2 bg-white shadow-md rounded-xl dark:bg-gray-800 dark:text-white dark:border dark:border-gray-700">
        <table className="w-full">
          <thead className="">
            <tr className="border-b h-12 font-bold dark:border-gray-700">
              <th className="w-1/12 text-left p-2">ID</th>
              <th className="w-3/12 text-left p-2">Name</th>
              <th className="w-5/12 text-left p-2">Description</th>
              <th className="w-2/12 text-left p-2"></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="4">
                  <div className="flex justify-center items-center py-14">
                    <FaSpinner className="animate-spin text-3xl text-blue-500" />
                  </div>
                </td>
              </tr>
            ) : filteredRunGroups.length > 0 ? (
              filteredRunGroups.map((group) => (
                <tr
                  key={group.id}
                  className="hover:bg-gray-200 h-14 border-b dark:hover:bg-gray-700 dark:border-gray-700"
                >
                  <td className="p-4">{group.id}</td>
                  <td
                    className="text-blue-500 underline cursor-pointer"
                    onClick={() => handleOpenDetail(group.id)}
                  >
                    {group.name}
                  </td>
                  <td className="p-2">{group.description}</td>
                  <td>
                    <div className="flex justify-center items-center">
                      <button
                        onClick={() => runTestGroupOrchestrator(group.id)}
                      >
                        Run Group
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4">
                  <div className="flex justify-center items-center py-14">
                    <p>No Run Groups found. Create one at Add Test Run.</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TableRunsAndResults;
