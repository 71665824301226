import axios from "axios";
import React, { useState } from "react";
import { POST_PROJECTS_URL } from "../../utils/URLs";
import { toast } from "react-toastify";
//import { useSelector } from 'react-redux';

function FormCreatProject({setIsOpen, updateProjectsList}) {
    const [projectData, setProjectData] = useState({
        name: "",
        url: "",
        features: [{ name: "", path: "" }]
      });
    
      //PARA USAR USUARIOS EN EL FLUJO
      // const currentUser = useSelector(state => state.currentUser);
      // console.log(currentUser);
      // console.log(currentUser.reloadUserInfo.localId);
    
      const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
    
      const toggleModal = () => {
        setIsInfoModalOpen(!isInfoModalOpen);
      };

      const handleModalClose = () => {
        setIsOpen(false)
      }
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setProjectData((prevData) => ({
          ...prevData,
          [name]: value,
        }));
      };
    
      const handlePageChange = (index, e) => {
        const { name, value } = e.target;
        let features = [...projectData.features];
        features[index][name] = value;
        setProjectData({ ...projectData, features });
      };
    
      const addPage = () => {
        let features = [...projectData.features, { name: "", path: "" }];
        setProjectData({ ...projectData, features });
      };
    
      const handleSubmit = async (e) => {
        e.preventDefault();
        const infoProject = { ...projectData, user_id: 4 };//USER HARDCODEADO
        
        try {
          const response = await axios.post(POST_PROJECTS_URL, infoProject);
          console.log(response);
          toast.success("Project created");
          setProjectData({
            name: "",
            url: "",
            features: [{ name: "", path: "" }],
          });
          updateProjectsList();
          setIsOpen(false)
        } catch (error) {
          console.error("Error al crear el proyecto:", error);
          toast.error("Failed to create the project");
        }
      };
      
  return (
    <div className=" flex justify-center items-center w-3/4">
      <div className="bg-white border border-solid border-blue-950 h-4/5 w-3/4 rounded-xl p-8 overflow-y-auto max-h-4/5 dark:bg-gray-800 dark:border-gray-700 relative">
        {isInfoModalOpen && (
          <div className="fixed inset-0 flex justify-center items-center dark:text-black">
            <div className="bg-white p-6 border border-black rounded-lg shadow-lg relative">
              <p>Aquí va la información sobre el formulario...</p>

              <button
                onClick={toggleModal}
                className="absolute top-0 right-0 bg-transparent text-black p-2 rounded"
              >
                X
              </button>
            </div>
          </div>
        )}

        <button className=" font-bold absolute top-2 right-4" onClick={handleModalClose}>X</button>

        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
              htmlFor="name"
            >
              Project Name
            </label>
            <input
              className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
              type="text"
              id="name"
              name="name"
              value={projectData.name}
              onChange={handleChange}
              required
            />
          </div>

          <div className="mb-4">
            <label
              className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
              htmlFor="url"
            >
              Project URL
            </label>
            <input
              className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
              type="url"
              id="url"
              name="url"
              value={projectData.url}
              onChange={handleChange}
              required
            />
          </div>

          {projectData.features.map((page, index) => (
            <div key={index} className="mb-4">
              <div className="flex justify-between items-center">
                <div className="flex-grow mr-2">
                  <label
                    className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
                    htmlFor={`name-${index}`}
                  >
                    Page/Feature Name
                  </label>
                  <input
                    className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
                    type="text"
                    id={`name-${index}`}
                    name="name"
                    value={page.name}
                    onChange={(e) => handlePageChange(index, e)}
                    required
                  />
                </div>
                <div className="flex-grow mr-2">
                  <label
                    className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
                    htmlFor={`path-${index}`}
                  >
                    URL
                  </label>
                  <input
                    className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
                    type="text"
                    id={`path-${index}`}
                    name="path"
                    value={page.path}
                    onChange={(e) => handlePageChange(index, e)}
                    required
                  />
                </div>
                {projectData.features.length > 1 && (
                  <button
                    type="button"
                    onClick={() => {
                      let features = [...projectData.features];
                      features.splice(index, 1);
                      setProjectData({ ...projectData, features });
                    }}
                    className="bg-red-800 w-32 mr-2 px-2 h-10 border border-red-800 rounded-full text-sm text-white hover:bg-inherit hover:text-red-800 hover:border hover:border-red-800 hover:text-sm dark:hover:bg-inherit"
                  >
                    Remove
                  </button>
                )}
              </div>
            </div>
          ))}

          <div className="mb-4">
            <button
              type="button"
              onClick={addPage}
              className="bg-blue-800 w-32 mr-2 px-2 h-10 border border-blue-800 rounded-full text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
            >
              Add Page
            </button>
          </div>

          <div className="flex justify-end">
            <button
              className="bg-blue-800 w-32 mr-2 px-2 h-10 border border-blue-800 rounded-full text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
              type="submit"
            >
              Create Project
            </button>
          </div>
        </form>
        <button
          type="button"
          onClick={toggleModal}
          className="absolute bottom-2 right-2 bg-blue-800 text-white rounded-full h-6 w-6 flex items-center justify-center text-lg cursor-pointer"
        >
          ?
        </button>
      </div>
    </div>
  );
}

export default FormCreatProject;
