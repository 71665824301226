import React, { useEffect, useState } from "react";
import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/theme-monokai";
import SaveConfirmationModal from "./SaveConfirmationModal";
import axios from "axios";
import {
  PUT_CODEFILES_URL,
  GET_CODEFILES_BY_ID_URL,
  SINGLE_EXECUTION,
} from "../../utils/URLs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";

const CodeRunner = ({
  codeId,
  isEditing,
  setIsEditing,
  output,
  setOutput,
  isLoadingCodeFile,
  setIsLoadingCodeFile,
}) => {
  //console.log(codeId);
  const [localCodeFiles, setLocalCodeFiles] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [editorValue, setEditorValue] = useState("");
  const [showSaveConfirmationModal, setShowSaveConfirmationModal] =
    useState(false);
  const [originalCode, setOriginalCode] = useState("");
  const [testCaseId, setTestCaseId] = useState(null);
  const [testCaseTitle, setTestCaseTitle] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (codeId) {
      setIsLoadingCodeFile(true);
  
      const fetchData = async () => {
        try {
          const response = await axios.get(`${GET_CODEFILES_BY_ID_URL}${codeId}`);
          const { data } = response;
  
          const fileToSelect = selectedFile && data.files[selectedFile] ? selectedFile : Object.keys(data.files)[0];
  
          setSelectedFile(fileToSelect);
          setEditorValue(data.files[fileToSelect]);
          setLocalCodeFiles(data.files);
          setTestCaseId(data.test_case_id);
          setTestCaseTitle(data.test_title);
          setOriginalCode({ ...data.files });
        } catch (error) {
          console.error("Error al obtener el código:", error);
        } finally {
          setIsLoadingCodeFile(false);
        }
      };
  
      fetchData();
    }
  }, [codeId]);

  const handleFileSelect = (file) => {
    setSelectedFile(file);
    const fileContent = localCodeFiles[file];
    setEditorValue(fileContent);
  };

  const runCode = async () => {
    try {
      setIsLoading(true);
      console.log(testCaseId);
      const response = await axios.get(`${SINGLE_EXECUTION}${testCaseId}`);
      console.log(response.data);
      setOutput(response.data);
      console.log(output);
      console.log();
    } catch (error) {
      setOutput(`Error: ${error.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleSaveClickOnModal = async () => {
    try {
      const payload = { ...localCodeFiles, [selectedFile]: editorValue };

      const response = await axios.put(`${PUT_CODEFILES_URL}${codeId}`, {
        files: payload,
        test_case_id: testCaseId,
        test_title: testCaseTitle,
      });
      console.log(response.status);

      setLocalCodeFiles(payload);
      toast.success("Code edited successfully!");
    } catch (error) {
      console.error("Error al enviar la solicitud PUT:", error);
      toast.error("Error");
    }
  };

  const handleSaveClick = () => {
    setShowSaveConfirmationModal(true);
  };

  const handleEditCancel = () => {
    setShowSaveConfirmationModal(false);
  };

  const handleSaveConfirm = async () => {
    await handleSaveClickOnModal();
    setIsEditing(false);
    setShowSaveConfirmationModal(false);
  };

  const handleCancelClick = () => {
    setLocalCodeFiles(originalCode);
    const currentContent = originalCode[selectedFile];
    setEditorValue(currentContent);
    setIsEditing(false);
  };

  const determineStatus = (output) => {
    const { ok, failures, errors, skipped } = output;
    if (ok === 1) return "Success";
    if (failures === 1) return "Failure";
    if (errors === 1) return "Error";
    if (skipped === 1) return "Skipped";
    return "Unknown Status";
  };

  const formatOutput = (output) => {
    if (!output) return null;

    const { total_time, last_run } = output;
    const dateTime = last_run ? new Date(last_run) : null;
    const formattedDate = dateTime ? dateTime.toLocaleDateString() : "N/A";
    const formattedTime = dateTime ? dateTime.toLocaleTimeString() : "N/A";

    const status = determineStatus(output);

    return {
      date: formattedDate,
      time: total_time,
      status,
      runTime: formattedTime,
    };
  };

  const formattedOutput = formatOutput(output);

  const getOutputStyle = (status) => {
    switch (status) {
      case "Success":
        return "bg-green-200 text-green-800";
      case "Failure":
        return "bg-orange-200 text-orange-800";
      case "Error":
        return "bg-red-200 text-red-800";
      case "Skipped":
        return "bg-yellow-200 text-yellow-800";
      default:
        return "bg-gray-200 text-gray-800";
    }
  };

  return (
    <div className="md:w-7/12">
      <div className="flex justify-center my-2">
        {isEditing ? (
          <div>
            <button
              onClick={handleSaveClick}
              className="bg-green-500 w-32 px-2 mx-2 h-10 rounded-md text-sm text-white border border-green-500 hover:bg-inherit hover:text-green-500 hover:border hover:border-green-500 hover:text-base"
            >
              Save
            </button>
            <button
              onClick={handleCancelClick}
              className="bg-red-500 w-32 px-2 h-10 rounded-md text-sm text-white border border-red-500 hover:bg-inherit hover:text-red-500 hover:border hover:border-red-500 hover:text-base"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div>
            <button
              onClick={handleEditClick}
              className="bg-blue-800 w-32 px-2 mx-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
            >
              Edit
            </button>
            <button
              onClick={runCode}
              className="bg-blue-800 w-32 px-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
            >
              Run Code
            </button>
          </div>
        )}
      </div>

      <div className="rounded-lg py-4 w-auto" style={{ backgroundColor: "#272822" }}>
        <div className="flex justify-center">
          <div className="flex justify-center mb-1 w-full">
            {localCodeFiles &&
              Object.keys(localCodeFiles).map((file) => (
                <div
                  key={file}
                  className={`px-4 py-1 text-white border border-black rounded-t-lg w-1/6 md:w-full ${
                    selectedFile === file
                      ? "border-b-0"
                      : "border-b border-black cursor-pointer"
                  } truncate`}
                  onClick={() => handleFileSelect(file)}
                >
                  {file}
                </div>
              ))}
          </div>
        </div>
        {codeId !== null ? (
          <div className="flex justify-center border-black">
            <AceEditor
              mode="javascript"
              theme="monokai"
              value={isLoadingCodeFile ? "Loading... " : editorValue}
              readOnly={!isEditing}
              onChange={(newCode) => {
                setEditorValue(newCode);
              }}
              name="code-editor"
              editorProps={{ $blockScrolling: true }}
              className={`w-full`}
              setOptions={{ useWorker: false }}
              style={{ width: '100%' }}
            />
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <AceEditor
              mode="javascript"
              theme="monokai"
              value={
                'const instruction = "Choose a Test Tile to see its Code here!"'
              }
              readOnly={true}
              name="code-editor"
              className={`w-full`}
              setOptions={{ useWorker: false }}
              style={{ width: '100%' }}
            />
          </div>
        )}
      </div>

      <div className="flex justify-center">
        {/* <h2>Output:</h2> */}
        <div
          className={`my-8 rounded-md h-40 w-full md:w-4/6 max-w-xl overflow-y-auto p-4 ${
            formattedOutput
              ? getOutputStyle(formattedOutput.status)
              : "text-white"
          }`}
          style={{
            backgroundColor: !formattedOutput ? "#272822" : "",
          }}
        >
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <FaSpinner className="animate-spin" />
            </div>
          ) : formattedOutput ? (
            <div>
              <h4 className="text-center text-4xl font-bold mb-4">
                {formattedOutput.status}
              </h4>
              <p className="text-center">
                Execution Time: {formattedOutput.time}
              </p>
              <p className="text-center">
                Last Run: {formattedOutput.date} at {formattedOutput.runTime}
              </p>
            </div>
          ) : (
            <h3>Run the code to see its result</h3>
          )}
        </div>
      </div>
      {/* <pre style={{ whiteSpace: "pre-wrap" }}>{output}</pre> */}
      {showSaveConfirmationModal && (
        <SaveConfirmationModal
          isOpen={showSaveConfirmationModal}
          onConfirm={handleSaveConfirm}
          onCancel={handleEditCancel}
        />
      )}
    </div>
  );
};

export default CodeRunner;
