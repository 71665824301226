// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faX } from "@fortawesome/free-solid-svg-icons";

// function ShowTicketDescriptionModal({ setIsOpen, ticketDescription, ticketKey }) {
//   const handleClose = () => {
//     setIsOpen(false);
//   };

//   const handleGoToJira = () => {
//     const jiraUrl = `https://aryx.atlassian.net/browse/${ticketKey}`;
//     window.open(jiraUrl, "_blank");
//   };

//   return (
//     <div className="bg-white border border-solid border-blue-950 h-auto w-3/4 rounded-xl p-8 relative dark:bg-gray-800 dark:border-gray-700">
//       <div className="flex justify-end absolute top-0 right-0 p-4">
//         <button className="text-blue-950 dark:text-white" onClick={handleClose}>
//           <FontAwesomeIcon icon={faX} />
//         </button>
//       </div>
//       <div className="mb-4">
//         <h2 className="text-blue-950 text-xl font-bold mb-2 dark:text-white">Description:</h2>
//         <p className="text-gray-700 dark:text-white py-10">{ticketDescription ? ticketDescription : "This Ticket has no description"}</p>
//       </div>
//       <button className="absolute bottom-4 right-4 bg-blue-800 px-2 h-10 border border-blue-800 rounded-full text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit" onClick={handleGoToJira}>
//         Go to Jira
//       </button>
//     </div>
//   );
// }

// export default ShowTicketDescriptionModal;
import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons"; // Correct icon name for "X"
import { marked } from 'marked';

function ShowTicketDescriptionModal({ setIsOpen, ticketDescription, ticketKey }) {
  const handleClose = () => {
    setIsOpen(false);
  };

  const handleGoToJira = () => {
    const jiraUrl = `https://aryx.atlassian.net/browse/${ticketKey}`;
    window.open(jiraUrl, "_blank");
  };

  // Parse markdown to HTML
  const createMarkup = (description) => {
    return { __html: marked(description) };
  };
  

  return (
    <div className="bg-white border border-solid border-blue-950 h-auto w-3/4 rounded-xl p-8 relative dark:bg-gray-800 dark:border-gray-700">
      <div className="flex justify-end absolute top-0 right-0 p-4">
        <button className="text-blue-950 dark:text-white" onClick={handleClose}>
          <FontAwesomeIcon icon={faXmark} />
        </button>
      </div>
      <div className="mb-4">
        <h2 className="text-blue-950 text-xl font-bold mb-2 dark:text-white">Description:</h2>
        <div
  className="text-gray-700 dark:text-white py-10"
  dangerouslySetInnerHTML={ticketDescription ? createMarkup(ticketDescription) : { __html: "Este ticket no tiene descripción" }}
/>
      </div>
      <button className="absolute bottom-4 right-4 bg-blue-800 px-2 h-10 border border-blue-800 rounded-full text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit" onClick={handleGoToJira}>
        Go to Jira
      </button>
    </div>
  );
}

export default ShowTicketDescriptionModal;
