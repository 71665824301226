import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React from "react";
import { POST_NEW_FEATURES } from "../../utils/URLs";

function FeatureForm({ onSubmit, onClose, project_id }) {
    console.log(project_id);
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const name = event.target.name.value;
        const path = event.target.path.value;
        try {
          const response = await axios.post(POST_NEW_FEATURES, { name, path, project_id });
          console.log(response);
          onSubmit();
        } catch (error) {
          console.error('Error making POST request:', error);
        }
      };

  return (
    <div className="bg-white border border-solid border-blue-950 rounded-xl p-8 overflow-y-auto max-h-4/5 dark:bg-gray-800 dark:border-gray-700 relative">
      <div className="absolute top-0 right-0 m-4">
        <button onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <h2 className="text-lg font-bold mb-4">Add Feature</h2>
      <form onSubmit={handleFormSubmit}>
        <div className="mb-4">
          <label
            className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
            htmlFor="name"
          >
            Name
          </label>
          <input
            className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
            id="name"
            type="text"
            placeholder="Feature Name"
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
            htmlFor="path"
          >
            Path
          </label>
          <input
            className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
            id="path"
            type="text"
            placeholder="Feature Path"
          />
        </div>

        <div className="flex justify-end">
          <button className="bg-blue-900 text-white px-4 py-2 rounded mr-2" type="submit">
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default FeatureForm;
