export const validateTestCaseForm = (testCaseData) => {
  if (!testCaseData.test_title.trim()) {
    return "Por favor, ingrese un título.";
  }

  if (!testCaseData.test_description.trim()) {
    return "Por favor, ingrese una descripción.";
  }

  if (!testCaseData.steps || testCaseData.steps.length === 0) {
    return "Por favor, ingrese al menos un paso.";
  }

  if (!testCaseData.expected_results.trim()) {
    return "Por favor, ingrese un resultado esperado.";
  }

  return null;
};

export const validateEditTestCaseForm = (editedTestCase) => {
  if (!editedTestCase.test_title.trim()) {
    return "Por favor, ingrese un título.";
  }

  if (!editedTestCase.ticket_id.trim()) {
    return "Por favor, ingrese un ticket relacionado (Jira).";
  }

  if (!editedTestCase.test_description.trim()) {
    return "Por favor, ingrese una descripción.";
  }

  if (!editedTestCase.test_steps || editedTestCase.test_steps.length === 0) {
    return "Por favor, ingrese al menos un paso.";
  }

  if (!editedTestCase.expected_results.trim()) {
    return "Por favor, ingrese un resultado esperado.";
  }

  return null; // La validación es exitosa
};
