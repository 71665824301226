// import React from 'react';
// import { Navigate } from 'react-router-dom';
// import { useSelector } from 'react-redux';
// import DashboardUsuario from './views/dashboardUsuario';

// function ProtectedRoute({ component: Component, toggleDarkMode, darkMode }) {
//   const isAuthenticated = useSelector(state => state.isAuthenticated);
//   const currentUser = useSelector(state => state.currentUser);
//   console.log(currentUser);
//   console.log(isAuthenticated);

//   return isAuthenticated ? <DashboardUsuario toggleDarkMode={toggleDarkMode} darkMode={darkMode} /> : <Navigate to="/login" />;
// }

// export default ProtectedRoute;

import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardUsuario from './views/dashboardUsuario';

function ProtectedRoute({ component: Component, toggleDarkMode, darkMode }) {
  // Retrieve user data from LocalStorage
  const storedUser = localStorage.getItem('user');
  const isAuthenticated = storedUser ? true : false;

  return isAuthenticated ? (
    <DashboardUsuario toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
  ) : (
    <Navigate to="/login" />
  );
}

export default ProtectedRoute;
