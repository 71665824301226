import React, { useState, useEffect } from "react";
import ReactGA from 'react-ga4';
import { Routes, Route, useLocation } from 'react-router-dom';
import DashboardUsuario from './views/dashboardUsuario';
import Home from './views/home';
import LogIn from './views/logIn';
import RecoverPassword from './views/recoverPassword';
import SingUp from './views/singUp';
import ContactUs from './views/contact';
import ErrorPage from './views/errorPage';
import ProtectedRoute from './ProtectedRout';
import WelcomePage from "./views/welcomePage";
import { useDispatch } from "react-redux";
import { setCurrentUser } from "./redux/actions";

const TRACKING_ID = process.env.REACT_APP_TRACKING_ID; 

function useAnalytics() {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID);
  }, []);

  useEffect(() => {
    const currentPath = location.pathname + location.search;
    ReactGA.send({ hitType: 'pageview', page: currentPath });
  }, [location]);
}

function App() {
  const initialDarkMode = localStorage.getItem('darkMode') === 'true' || false;
  const [darkMode, setDarkMode] = useState(initialDarkMode);

  useAnalytics();

  const dispatch = useDispatch();
  
  useEffect(() => {
    // Load user data from LocalStorage when the app starts
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      const userData = JSON.parse(storedUser);
      dispatch(setCurrentUser(userData));
    }
  }, [dispatch]);

  useEffect(() => {
    const htmlElement = document.documentElement;
    if (darkMode) {
      htmlElement.classList.add('dark');
    } else {
      htmlElement.classList.remove('dark');
    }
    localStorage.setItem('darkMode', darkMode);
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode(prevDarkMode => !prevDarkMode);
  };

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home toggleDarkMode={toggleDarkMode} darkMode={darkMode}/>}></Route>
        <Route path="/login" element={<LogIn/>}></Route>
        <Route path="/singup" element={<SingUp/>}></Route>
        <Route path="/recoverPassword" element={<RecoverPassword/>}></Route>
        <Route path="/contact" element={<ContactUs/>}></Route>
        <Route path="*" element={<ErrorPage/>}></Route>
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/dashboard" element={
          <ProtectedRoute toggleDarkMode={toggleDarkMode} darkMode={darkMode}>
            <DashboardUsuario/>
          </ProtectedRoute>
        }/>
      </Routes>
    </div>
  );
}

export default App;
