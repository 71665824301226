import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getTestCases,
  filterByTicket,
  filterByAutomationStatus,
  searchByName,
} from "../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import FormCreatTestCaseManual from "./FormCreatTestCaseManual";

function TestCasesFilterComponent() {
  const [selectedTicket, setSelectedTicket] = useState("All");
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);

  const dispatch = useDispatch();

  const allTastCases = useSelector((state) => state.testCases);
  console.log(allTastCases);
  useEffect(() => {
    dispatch(getTestCases());
  }, [dispatch]);

  //Filtro por Ticket
  function handleFilterTicket(event) {
    event.preventDefault();
    setSelectedTicket(event.target.value);
    dispatch(filterByTicket(event.target.value));
  }

  //Filtro por Automation Status
  const handleFilterAutomationStatus = (event) => {
    event.preventDefault();
    dispatch(filterByAutomationStatus(event.target.value));
  };

  //Filtrar por Nombre
  const handleSearchByName = (event) => {
    event.preventDefault();
    dispatch(searchByName(event.target.value));
  };

  function handleReset(event) {
    event.preventDefault();
    dispatch(getTestCases());
    setSelectedTicket("All");
  }

  const handleFormClick = async () => {
    setIsFormModalOpen(true);
  };

  const handleUpdateTestCases = () => {
    dispatch(getTestCases());
  };

  return (
    <div className="bg-white shadow-md rounded-xl h-auto p-4 mb-6 flex flex-wrap gap-4 justify-evenly dark:bg-gray-800 dark:text-white dark:border dark:border-gray-700">
      <div className="filter-group flex flex-col w-full sm:w-1/2 md:w-auto">
        <label htmlFor="nameFilter" className="text-sm">
          Search by Name:
        </label>
        <input
          id="nameFilter"
          className=" w-full md:w-48 p-1 border-2 border-blue-950 rounded-md dark:border dark:bg-gray-800 dark:border-gray-700"
          placeholder="Title..."
          type="text"
          onChange={(event) => handleSearchByName(event)}
        />
      </div>

      <div className="filter-group flex flex-col w-full sm:w-1/2 md:w-auto">
        <label htmlFor="ticketFilter" className="text-sm">
          Related Ticket:
        </label>
        <select
          id="ticketFilter"
          className=" w-full md:w-48 p-1 border-2 border-blue-950 rounded-md dark:border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
          onChange={(event) => handleFilterTicket(event)}
          value={selectedTicket}
        >
          <option value="All">All</option>
          {Array.from(
            new Set(allTastCases.map((ticket) => ticket.ticket_id))
          ).map((uniqueTicket, index) => (
            <option value={uniqueTicket} key={index}>
              {allTastCases.find((ticket) => ticket.ticket_id === uniqueTicket)
                .ticket_id}
            </option>
          ))}
        </select>
      </div>

      <div className="filter-group flex flex-col w-full sm:w-1/2 md:w-auto">
        <label htmlFor="automationStatusFilter" className="text-sm">
          Automation:
        </label>
        <select
          id="automationStatusFilter"
          className="w-full md:w-48 p-1 border-2 border-blue-950 rounded-md dark:border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
          onChange={(event) => handleFilterAutomationStatus(event)}
          defaultValue="All"
        >
          <option value="All">All</option>
          <option value="false">Not Started</option>
          <option value="true">Completed</option>
        </select>
      </div>

      <button className="bg-blue-800 px-2 mt-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-white hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit" onClick={(event) => handleReset(event)}>
        Reset Filters
      </button>

      <button className="bg-blue-800 px-2 mt-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-white hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit" onClick={handleFormClick} title="Creat Test Case">
        Add Case <FontAwesomeIcon icon={faPlus} />
      </button>

      {isFormModalOpen && (
        <div className={`fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-md ${isFormModalOpen ? "block" : "hidden"}`}>
          <FormCreatTestCaseManual setIsOpen={setIsFormModalOpen} handleUpdateTestCases={handleUpdateTestCases} />
        </div>
      )}
    </div>
  );
}

export default TestCasesFilterComponent;
