function StatusDropdown({ currentStatus, testCaseId }) {
  console.log(currentStatus);
    const statusOptions = [
      { value: "passed", label: "Passed", color: "#28a745" },
      { value: "blocked", label: "Blocked", color: "#dc3545" },
      { value: "retest", label: "Retest", color: "#ffc107" },
      { value: "failed", label: "Failed", color: "#dc3545" },
      { value: "skipped", label: "TIA-Skipped", color: "#6f42c1" }
    ];
  
    const handleStatusChange = (testCaseId, newStatus) => {
        // UN PUT AL BACK
        alert(`Status changed for test case ID ${testCaseId} to ${newStatus}`);
      };
  
    return (
      <select
        value={currentStatus}
        onChange={(e) => handleStatusChange(testCaseId, e.target.value)}
        style={{ color: statusOptions.find(opt => opt.value === currentStatus)?.color }}
        className=" p-1 border-2 border-blue-950 rounded-md dark:border dark:bg-inherit dark:border-gray-700"
      >
        <option>{currentStatus}</option>
        {statusOptions.map((option) => (
          <option
            key={option.value}
            value={option.value}
            style={{ backgroundColor: option.color }}
          >
            {option.label}
          </option>
        ))}
      </select>
    );
  }
  
export default StatusDropdown;