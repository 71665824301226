import React, { useState } from "react";
import axios from "axios";
import { validateTestCaseForm } from "../../utils/validations";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";
import { POST_TEST_CASES_URL } from "../../utils/URLs";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

function FormCreatTestCaseManual({ setIsOpen, handleUpdateTestCases }) {
  const [testCaseData, setTestCaseData] = useState({
    test_title: "",
    test_description: "",
    steps: [],
    expected_results: "",
    ticket_id: "",
    approved: true,
    is_edited: false,
    is_automated: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTestCaseData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationError = validateTestCaseForm(testCaseData);

    if (validationError) {
      toast.error(validationError);
      return;
    }

    const payload = {
      ticket_id: testCaseData.ticket_id,
      test_title: testCaseData.test_title,
      test_description: testCaseData.test_description,
      test_steps: testCaseData.steps,
      expected_results: testCaseData.expected_results,
      approved: testCaseData.approved,
      is_edited: testCaseData.is_edited,
      is_automated: testCaseData.is_automated,
    };
    console.log(payload);
    try {
      const response = await axios.post(POST_TEST_CASES_URL, payload);
      console.log("Datos publicados con éxito:", response.data);
      setIsOpen(false);
      toast.success("Test Case created successfully!")
      handleUpdateTestCases();
    } catch (error) {
      toast.error("Error creating Test Case!")
      console.error("Error al publicar los datos:", error);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleAddStep = () => {
    setTestCaseData((prevData) => ({
      ...prevData,
      steps: [...prevData.steps, ""],
    }));
  };

  const handleRemoveStep = (index) => {
    setTestCaseData((prevData) => {
      const updatedSteps = [...prevData.steps];
      updatedSteps.splice(index, 1);
      return { ...prevData, steps: updatedSteps };
    });
  };

  const handleStepChange = (e, index) => {
    const updatedSteps = [...testCaseData.steps];
    updatedSteps[index] = e.target.value;
    setTestCaseData((prevData) => ({
      ...prevData,
      steps: updatedSteps,
    }));
  };

  return (
    <div className="bg-white border border-solid  border-blue-950 h-4/5 w-3/4 rounded-xl p-8 overflow-y-auto max-h-4/5 dark:bg-gray-800 dark:border-gray-700">
      <div className=" flex justify-end">
        <button className="text-blue-950 dark:text-white" onClick={handleClose}>
          <FontAwesomeIcon icon={faX} />
        </button>
      </div>
      <h1 className="text-blue-950 text-2xl font-bold mb-4 dark:text-white">
        Create Test Case
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label
            className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
            htmlFor="title"
          >
            Title:
          </label>
          <input
            className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
            type="text"
            id="test_title"
            name="test_title"
            value={testCaseData.test_title}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
            htmlFor="ticket_id"
          >
            Related Ticket(Jira):
          </label>
          <input
            className="w-full h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
            type="text"
            id="ticket_id"
            name="ticket_id"
            value={testCaseData.ticket_id}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
            htmlFor="description"
          >
            Description:
          </label>
          <textarea
            className="w-full h-20 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
            id="test_description"
            name="test_description"
            value={testCaseData.test_description}
            onChange={handleChange}
            required
          />
        </div>

        <div className="mb-4">
          <label
            className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
            htmlFor="test_steps"
          >
            Steps:
          </label>
          {testCaseData.steps.map((steps, index) => (
            <div key={index} className="mb-2 flex">
              <textarea
                className="w-full h-10 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
                id={`steps-${index}`}
                name={`steps-${index}`}
                value={steps}
                onChange={(e) => handleStepChange(e, index)}
                required
              />
              <button
                type="button"
                className="ml-2 px-2 py-1 text-red-700 rounded-full"
                onClick={() => handleRemoveStep(index)}
              >
                X
              </button>
            </div>
          ))}
          <div className=" flex justify-end">
            <button
              type="button"
              className="bg-blue-800 w-32 mr-2 px-2 h-10 border border-blue-800 rounded-full text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
              onClick={handleAddStep}
            >
              Add Step
            </button>
          </div>
        </div>

        <div className="mb-4">
          <label
            className="block text-blue-950 text-sm font-bold mb-2 dark:text-white"
            htmlFor="expected_results"
          >
            Expected Result:
          </label>
          <textarea
            className="w-full h-20 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:bg-gray-800 dark:border-gray-700"
            id="expected_results"
            name="expected_results"
            value={testCaseData.expected_results}
            onChange={handleChange}
            wrap="soft"
            required
          />
        </div>

        <div className=" flex justify-end">
          <button
            className="bg-blue-800 w-32 mr-2 px-2 h-10 border border-blue-800 rounded-full text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
            type="submit"
          >
            Create Test Case
          </button>
        </div>
      </form>
    </div>
  );
}

export default FormCreatTestCaseManual;
