import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import LogInImagen from '../utils/imagenes/LogInImagen.png';
import LogoBugster from '../utils/imagenes/LogoBugster.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { auth } from '../Firebase/firebase';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth'; 
import { useDispatch } from 'react-redux';
import { setCurrentUser } from '../redux/actions';
import { useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc } from 'firebase/firestore';
import ReactGA from 'react-ga4';
import { toast } from 'react-toastify';

function LogIn() {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        dispatch(setCurrentUser(user));
        ReactGA.event({
          category: "Auth",
          action: "Login",
          label: "Firebase"
        });
      } else {
        dispatch(setCurrentUser(null));
      }
    });

    return () => unsubscribe();
  }, [dispatch]);

  const db = getFirestore();

  // const signInWithGoogle = () => {
  //   const provider = new GoogleAuthProvider();
  //   signInWithPopup(auth, provider)
  //     .then(async (result) => {
  //       const user = result.user;
  //       const emailRef = doc(db, 'allowedEmails', user.email);
  //       const emailDoc = await getDoc(emailRef);
  //       if (emailDoc.exists()) {
  //         dispatch(setCurrentUser(user));
  //         if (ReactGA.gtag) {
  //           ReactGA.event({
  //             category: "Auth",
  //             action: "SignInWithGoogle",
  //             label: user.email
  //           });
  //         }
  //         navigate('/');
  //       } else {
  //         await auth.signOut();
  //         alert("Access denied");
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //       alert("Authentication Failed");
  //     });
  // };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();

    signInWithPopup(auth, provider)
        .then(async (result) => {
            const user = result.user;
            const emailRef = doc(db, 'allowedEmails', user.email);
            const emailDoc = await getDoc(emailRef);

            if (emailDoc.exists()) {
                // Save user data to LocalStorage
                localStorage.setItem('user', JSON.stringify(user));

                dispatch(setCurrentUser(user));
                if (ReactGA.gtag) {
                    ReactGA.event({
                        category: "Auth",
                        action: "SignInWithGoogle",
                        label: user.email
                    });
                }
                navigate('/');
            } else {
                await auth.signOut();
                toast.error("Access denied");
            }
        })
        .catch((error) => {
            console.log(error);
            toast.error("Authentication Failed");
        });
};


  return (
    <div className="flex flex-wrap h-screen bg-gray-100 dark:bg-gray-800">
      <div className="w-full md:w-1/3 p-8 flex flex-col items-center justify-center mx-auto">
        <div className="flex mb-4 flex-col w-full">
          <div className="w-full">
            <img src={LogoBugster} alt="Imagen" className="w-16 h-16 mx-auto mb-2" />
            <h2 className="text-3xl text-blue-950 font-semibold text-center dark:text-white">Log In</h2>

            <div className="p-2 mt-4 flex">
              <button onClick={signInWithGoogle} className="bg-blue-800 text-white p-2 mr-2 w-full rounded hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit">
                <FontAwesomeIcon icon={faGoogle} className="mr-2" />Google
              </button>

              <button type="submit" className="bg-blue-800 text-white p-2 ml-2 w-full rounded hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit">
                <FontAwesomeIcon icon={faFacebookF} className="mr-2" />Facebook
              </button>
            </div>
          </div>
        </div>

        <div className="w-full">
          <form>
            {/* Formulario de login tradicional aquí */}
            {/* ... */}
          </form>
        </div>

        <div className="w-72 mx-auto mt-6 text-center text-xs dark:text-white">
          <p>Don’t have an account yet? <Link to="/signup" className="text-sky-700">New Account</Link></p>
        </div>

      </div>

      <div className="hidden md:flex md:w-2/3 h-screen items-center justify-center bg-gray-200 dark:bg-gray-700">
        <div className="w-3/6 h-3/6">
          <img src={LogInImagen} alt="Imagen" className="w-full h-full m-auto" />
        </div>
      </div>
    </div>
  );
}

export default LogIn;
