import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getTestCases, getRunGroups } from "../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { POST_RUN_GROUP } from "../../utils/URLs";
import { toast } from "react-toastify";


function AddTestRun({ setIsOpen }) {
  const dispatch = useDispatch()

  const [formData, setFormData] = useState({
    name: "",
    description: "",
    test_case_ids: [],
  });

  const [selectedTestCases, setSelectedTestCases] = useState([]);

  const testCases = useSelector((state) => state.testCases);
   const testCasesAutomated = testCases//.filter((testCase) => testCase.is_automated === true);
   console.log(testCasesAutomated);

  useEffect(() => {
    dispatch(getTestCases());
  }, [dispatch]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    console.log(formData);
  };

  const handleTestCaseSelection = (e) => {
    const selectedTestCaseId = e.target.value;


    if (selectedTestCaseId === "all") {
      const allTestCaseIds = testCasesAutomated.map((testCase) => testCase.id);
      setSelectedTestCases(allTestCaseIds);
    } else {
      if (!selectedTestCases.includes(selectedTestCaseId)) {
        setSelectedTestCases((prevSelected) => [...prevSelected, selectedTestCaseId]);
      }
    }
  };

  const handleRemoveSelectedTestCase = (testCaseId) => {
    setSelectedTestCases((prevSelected) => prevSelected.filter((id) => id !== testCaseId));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const dataToSend = {
      ...formData,
      test_case_ids: selectedTestCases,
    };
    const response = await axios.post(POST_RUN_GROUP, dataToSend)
    console.log(response);
    toast.success("Test Run created successfully!")
    dispatch(getRunGroups());
    setIsOpen(false);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="bg-white border border-solid border-blue-950 h-3/5 w-3/4 rounded-xl p-8 overflow-y-auto max-h-4/5 dark:bg-gray-800 dark:border-gray-700">
      <div className="flex justify-end">
        <button className="text-blue-950 dark:text-white" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>
      <h1 className="text-blue-950 text-2xl font-bold mb-4 dark:text-white">Create Test Run</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="name" className="block text-sm font-medium text-gray-700 dark:text-white">
            Name:
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.nombre}
            onChange={handleChange}
            className="mt-1 p-2 border rounded-md w-full dark:bg-gray-800 dark:border-gray-700"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-sm font-medium text-gray-700 dark:text-white">
            Description:
          </label>
          <textarea
            id="description"
            name="description"
            value={formData.descripcion}
            onChange={handleChange}
            rows="3"
            className="mt-1 p-2 border rounded-md w-full dark:bg-gray-800 dark:border-gray-700"
          ></textarea>
        </div>
        <div className="mb-4">
          <label htmlFor="test_case_ids" className="block text-sm font-medium text-gray-700 dark:text-white">
            Select Test Case:
          </label>
          <select
            id="test_case_ids"
            name="test_case_ids"
            value={""}
            onChange={handleTestCaseSelection}
            className="mt-1 p-2 border rounded-md w-full dark:bg-gray-800 dark:border-gray-700"
          >
            <option value="" disabled>Select a Test Case</option>
            <option value="all">Select All Test Cases Automated</option>
            {testCasesAutomated.map((testCase) => (
              <option key={testCase.id} value={testCase.id}>
                {testCase.test_title}
              </option>
            ))}
          </select>
        </div>
        {selectedTestCases.length > 0 && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700 dark:text-white">
              Selected Test Cases:
            </label>
            <ul className="mt-1">
              {selectedTestCases.map((testCaseId) => (
                <li key={testCaseId} className="text-blue-950 dark:text-white">
                  {console.log(testCasesAutomated)}
                  {console.log(selectedTestCases)}
                  {console.log(testCaseId)}
                  <FontAwesomeIcon className=" w-1 mr-2" icon={faCircle} />{testCasesAutomated.find((testCase) => testCase.id == testCaseId)?.test_title}
                  <button
                    type="button"
                    className="ml-2 text-red-500"
                    onClick={() => handleRemoveSelectedTestCase(testCaseId)}
                  >
                    <FontAwesomeIcon className=" w-3" icon={faTrash} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-800 w-32 mr-2 px-2 h-10 border border-blue-800 rounded-full text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
          >
            Save
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddTestRun;
