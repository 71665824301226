import { useState, useEffect } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getTickets } from "../../redux/actions";
import CreateTicketModal from "./CreateTicketModal";
import { TC_GENERATION_URL } from "../../utils/URLs";
import ShowTicketDescriptionModal from "./ShowTicketDescriptionModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";
import ConectToJiraModal from "./ConnectToJiraModal";

function TableTicketsComponent() {
  const [currentTicketKey, setCurrentTicketKey] = useState("");
  const [testCasesCreatedByAI, setTestCasesCreatedByAI] = useState(null);
  const [showTicketDescriptionModal, setShowTicketDescriptionModal] =
    useState(false);
  const [ticketDescription, setTicketDescription] = useState(null);
  const [successfulRequestKey, setSuccessfulRequestKey] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingByRow, setLoadingByRow] = useState({});
  const [isCreating, setIsCreating] = useState(false);
  const [showJiraConnectModal, setShowJiraConnectModal] = useState(false);

  // ESTADO GLOBAL PARA TICKETS
  const ticketsG = useSelector((state) => state.filteredTickets);
  console.log(ticketsG);

  const dispatch = useDispatch();

  useEffect(() => {
    if (ticketsG.length === 0) {
      setIsLoading(true);
      dispatch(getTickets()).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [dispatch, ticketsG.length]);

  const handelOpenCreationModal = () => {
    setIsModalVisible(true);
  };

  const handleCreateClick = async (ticket) => {
    setIsCreating(true);
    setLoadingByRow((prevLoadingByRow) => ({
      ...prevLoadingByRow,
      [ticket.key]: true,
    }));

    setCurrentTicketKey(ticket.key);
    try {
      const response = await axios.post(TC_GENERATION_URL, {
        acceptance_criteria: ticket.fields.description,
      });
      const { data } = response;
      const testCasesCreatedByAIResponse = data.issues;
      setTestCasesCreatedByAI(testCasesCreatedByAIResponse);
      console.log(testCasesCreatedByAI);
      toast.success("Test Case created successfully!");
      setSuccessfulRequestKey(ticket.key);
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Test Case not created!");
    } finally {
      setLoadingByRow((prevLoadingByRow) => ({
        ...prevLoadingByRow,
        [ticket.key]: false,
      }));
      setIsCreating(false);
    }
  };

  const handleShowTicketDescription = (description, key) => {
    setCurrentTicketKey(key);
    setTicketDescription(description);
    setShowTicketDescriptionModal(true);
  };

  const handleModalClose = () => {
    setSuccessfulRequestKey(null);
    setIsModalVisible(false);
  };

  function formatIssueType(issueTypeName) {
    return issueTypeName.toUpperCase() === "SUBTAREA"
      ? "SUB-TASK"
      : issueTypeName.toUpperCase();
  }

  return (
    <div className=" bg-white rounded-xl dark:bg-gray-800 dark:text-white dark:border dark:border-gray-700">
      <div className=" rounded overflow-hidden shadow-md">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="">
            <tr className="border-b  h-14 dark:border-gray-700">
              {/* <th className=" w-1/12 text-left p-2 ">Check Box</th> */}
              {/* HASTA QUE TENGAMOS CRUD USUARIOS, cambiar tamano de las filas al agregar nuevamente */}
              <th className=" w-1/12 text-left p-2 ">Ticket Code</th>
              <th className=" w-4/12 text-left p-2 ">Summary</th>
              <th className=" w-2/12 text-left p-2 ">Issue Type</th>
              <th className=" w-2/12 text-left p-2 ">Assignee</th>
              <th className=" w-1/12 p-2 ">Creation</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="5">
                  <div className="flex justify-center items-center py-14">
                    <FaSpinner className="animate-spin text-3xl text-blue-500" />
                  </div>
                </td>
              </tr>
            ) : ticketsG.length > 0 ? (
              ticketsG.map((ticket) => (
                <tr
                  key={ticket.key}
                  className=" border-b h-14 hover:bg-gray-200 dark:hover:bg-gray-700 dark:border-gray-700"
                >
                  {/* <td className="px-10">
                  <input
                    type="checkbox"
                    name="myCheckbox"
                    className="m-auto"
                  />
                </td> */}

                  <td className="font-semibold pl-6">{ticket.key}</td>

                  <td className=" px-4">
                    <p
                      className=" text-blue-500 underline cursor-pointer"
                      onClick={() =>
                        handleShowTicketDescription(
                          ticket.fields.description,
                          ticket.key
                        )
                      }
                    >
                      {ticket.fields.summary}
                    </p>
                  </td>
                  <td
                    style={{
                      paddingLeft: "8px",
                      fontWeight: "bold",
                      color:
                        ticket.fields.issuetype.name === "Task"
                          ? "#4A90E2"
                          : ticket.fields.issuetype.name === "Epic"
                          ? "#9B51E0"
                          : ticket.fields.issuetype.name === "Bug"
                          ? "#F44336"
                          : ticket.fields.issuetype.name === "Story"
                          ? "#4CAF50"
                          : "#808080",
                    }}
                  >
                    {formatIssueType(ticket.fields.issuetype.name)}
                  </td>

                  <td className=" pl-2">
                    {ticket.fields.assignee
                      ? ticket.fields.assignee.displayName
                      : "Unassigned"}
                  </td>

                  <td className="pl-2">
                    {ticket.fields.description && (
                      <>
                        {loadingByRow[ticket.key] ? (
                          <div className="flex justify-center">
                            <FaSpinner className="animate-spin text-blue-500" />
                          </div>
                        ) : (
                          <div className="flex justify-center">
                            {ticket.key === successfulRequestKey ? (
                              <button
                                className="border border-blue-500 text-blue-500 rounded px-4 py-2 flex items-center"
                                onClick={() => handelOpenCreationModal()}
                              >
                                Open Modal
                              </button>
                            ) : (
                              <button
                                className="border border-blue-500 text-blue-500 rounded px-4 py-2 flex items-center"
                                onClick={() => handleCreateClick(ticket)}
                                disabled={
                                  isCreating || loadingByRow[ticket.key]
                                } // Disable button if any 'Create' is processing
                              >
                                Create
                              </button>
                            )}
                          </div>
                        )}
                      </>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">
                  <div className="flex flex-col justify-center items-center my-10">
                    <p className="text-center font-medium text-lg">
                      Create your first Tickets in Jira to see them visualized
                      here or connect Jira to start.
                    </p>
                    <button
                      className="bg-blue-800 px-2 mt-6 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
                      onClick={() => setShowJiraConnectModal(true)}
                    >
                      Connect to Jira
                    </button>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
      </div>

      {isModalVisible && (
        <div
          className={`fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-md ${
            isModalVisible ? "block" : "hidden"
          }`}
        >
          <CreateTicketModal
            setIsOpen={setIsModalVisible}
            testCasesCreatedByAI={testCasesCreatedByAI}
            currentTicketKey={currentTicketKey}
            onModalClose={handleModalClose}
          />
        </div>
      )}

      {showTicketDescriptionModal && (
        <div
          className={`fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-md ${
            showTicketDescriptionModal ? "block" : "hidden"
          }`}
        >
          <ShowTicketDescriptionModal
            ticketDescription={ticketDescription}
            ticketKey={currentTicketKey}
            setIsOpen={setShowTicketDescriptionModal}
          />
        </div>
      )}

      {showJiraConnectModal && (
        <div
          className={`fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-md`}
        >
          <ConectToJiraModal
            onClose={() => setShowJiraConnectModal(false)}
          ></ConectToJiraModal>
        </div>
      )}
    </div>
  );
}

export default TableTicketsComponent;
