import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { logout } from "../../redux/actions";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function Settings({ toggleDarkMode, darkMode }) {
  const [tabIndex, setTabIndex] = useState(0);

  const dispatch = useDispatch();

  const handleTabSelect = (index) => {
    setTabIndex(index);
  };

  const authenticateJira = () => {
    // Lógica para autenticar con Jira
    alert("Authenticate with Jira");
  };

  const authenticateGitHub = () => {
    // Lógica para autenticar con GitHub
    alert("Authenticate with GitHub");
  };

  const handleLogout = () => {
    dispatch(logout());
    setTimeout(() => window.location.reload(), 500);
  };

  return (
    <div>
      <Tabs selectedIndex={tabIndex} onSelect={handleTabSelect}>
        <TabList>
          <Tab>General</Tab>
          <Tab>Authentications</Tab>
          <Tab>Log Out</Tab>
        </TabList>

        <TabPanel>
          <div className=" mt-20 ml-10">
            <button
              className={`transition-all duration-300 ease-in-out w-32 px-2 h-10 mr-2 rounded-md ${
                darkMode
                  ? "bg-gray-800 text-white border border-white"
                  : "bg-white text-gray-800 border border-blue-950 dark:bg-gray-800 dark:text-white"
              }`}
              onClick={toggleDarkMode}
            >
              {darkMode ? "Light Mode" : "Dark Mode"}
            </button>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="flex flex-col items-center justify-center h-full mt-28">
            <div className="mb-4">
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded-md mr-2"
                onClick={authenticateJira}
              >
                Authenticate with Jira
              </button>
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded-md"
                onClick={authenticateGitHub}
              >
                Authenticate with GitHub
              </button>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <div className=" mt-20 ml-10">
            <button
              className="bg-red-500 text-white px-4 py-2 rounded-md"
              onClick={handleLogout}
            >
              Logout
            </button>

          </div>
        </TabPanel>
        
      </Tabs>
    </div>
  );
}

export default Settings;
