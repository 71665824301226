import { useState } from "react";
import AddTestRun from "./AddTestRun";

function FilterRunsAndResults({ updateIdFilter, updateNameFilter }) {
  const [isFormAddRunModalOpen, setIsFormAddRunModalOpen] = useState(false);
  const [idInput, setIdInput] = useState("");
  const [nameInput, setNameInput] = useState(""); 

  const handleFormClick = () => {
    setIsFormAddRunModalOpen(true);
  };

  const handleIdChange = (event) => {
    setIdInput(event.target.value);
    updateIdFilter(event.target.value); 
  };

  const handleNameChange = (event) => {
    setNameInput(event.target.value); 
    updateNameFilter(event.target.value); 
  };

  const handleResetFilters = () => {
    setIdInput("");
    setNameInput(""); 
    updateIdFilter(""); 
    updateNameFilter(""); 
  };

  return (
    <div className="bg-white shadow-md rounded-xl h-auto p-4 mb-6 flex flex-wrap gap-4 justify-evenly dark:bg-gray-800 dark:text-white dark:border dark:border-gray-700">
      <div className="filter-group flex flex-col w-full sm:w-1/2 md:w-auto">
        <label htmlFor="idFilter" className=" text-sm">
          Id:
        </label>
        <input
          id="idFilter"
          value={idInput} 
          onChange={handleIdChange}
          className="p-1 border-2 border-blue-950 rounded-md dark:border dark:bg-gray-800 dark:border-gray-700"
          placeholder="Id..."
          type="number"
        />
      </div>

      <div className="filter-group flex flex-col w-full sm:w-1/2 md:w-auto">
        <label htmlFor="nameFilter" className=" text-sm">
          Name:
        </label>
        <input
          id="nameFilter"
          value={nameInput} 
          onChange={handleNameChange}
          className="p-1 border-2 border-blue-950 rounded-md dark:border dark:bg-gray-800 dark:border-gray-700"
          placeholder="Name..."
          type="text"
        />
      </div>

      <button
        onClick={handleResetFilters}
        className="bg-blue-800 px-2 mt-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-white hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
      >
        Reset Filters
      </button>

      <button
        onClick={handleFormClick}
        className="bg-blue-800 px-2 mt-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-white hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
      >
        + Add Test Run
      </button>

      {isFormAddRunModalOpen && (
        <div
          className={`fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-md ${
            isFormAddRunModalOpen ? "block" : "hidden"
          }`}
        >
          <AddTestRun setIsOpen={setIsFormAddRunModalOpen}></AddTestRun>
        </div>
      )}
    </div>
  );
}

export default FilterRunsAndResults;
