import { useState, useEffect } from "react";
import axios from "axios";
import TestCaseWindow from "./TestCaseWindow";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { POST_TEST_CASES_URL } from "../../utils/URLs";
import { toast } from "react-toastify";

function CreateTicketModal({ setIsOpen, testCasesCreatedByAI, currentTicketKey, onModalClose }) {
  const [formData, setFormData] = useState(Object.values(testCasesCreatedByAI) || []);
  const [activeTestCaseIndex, setActiveTestCaseIndex] = useState(0);

  useEffect(() => {
    if (formData.length === 0) {
      onModalClose();
    }
  }, [formData, onModalClose]);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleTabChange = (index) => {
    setActiveTestCaseIndex(index);
  };

  const handleTabClose = (index) => {
    const updatedFormData = [...formData];
    updatedFormData.splice(index, 1);
    setFormData(updatedFormData);
    setActiveTestCaseIndex(index > 0 ? index - 1 : 0);
  };

  const postTestCaseData = async () => {
    const currentTestCase = formData[activeTestCaseIndex];
    const payload = {
      ticket_id: currentTicketKey,
      test_title: currentTestCase.testTitle,
      test_description: currentTestCase.description,
      test_steps: currentTestCase.steps,
      expected_results: currentTestCase.expectedResult,
      approved: true,
      is_edited: false,
      is_automated: false,
    };

    try {
      const response = await axios.post(
        POST_TEST_CASES_URL,
        payload
      );
      toast.success("Test Case creado con exito")
      console.log("Datos publicados con éxito:", response.data);
    } catch (error) {
      console.error("Error al publicar los datos:", error);
    }
  };

  const handleCreate = () => {
    postTestCaseData();
    handleTabClose(activeTestCaseIndex);
  };

  const handleSave = (updatedData) => {
    setFormData((prev) =>
      prev.map((testCase, index) =>
        index === activeTestCaseIndex ? { ...testCase, ...updatedData } : testCase
      )
    );
  };

  useEffect(() => {
    if (formData.length === 0) {
      setIsOpen(false);
    }
  }, [formData, setIsOpen]);

  return (
    <div className="w-4/6 bg-white border border-solid border-black rounded-xl dark:bg-gray-800 dark:border-gray-700">
      <div className="p-4 rounded relative">
        <button
          className="absolute top-2 right-2 p-1 text-gray-500 hover:text-gray-700"
          onClick={handleClose}
        >
          <FontAwesomeIcon icon={faX} />
        </button>

        <ul className="border-b border-gray-300 flex">
          {formData.map((testCase, index) => (
            <li
              key={index}
              className={`flex p-2 px-4 dark:text-white  ${
                index === activeTestCaseIndex
                  ? "border-b-2 border-blue-900 text-blue-900 dark:text-white dark:border-white"
                  : "text-gray-500 dark:text-gray-500"
              }`}
            >
              <button
                className={`w-full text-left p-2`}
                onClick={() => handleTabChange(index)}
              >
                Test Case {index + 1}
              </button>
              <button
                className="hover:text-gray-700"
                onClick={() => handleTabClose(index)}
              >
                <FontAwesomeIcon icon={faCircleXmark} />
              </button>
            </li>
          ))}
        </ul>

        {formData.length > 0 && (
          <TestCaseWindow
            key={activeTestCaseIndex}
            testTitle={formData[activeTestCaseIndex].testTitle}
            description={formData[activeTestCaseIndex].description}
            steps={formData[activeTestCaseIndex].steps.join("\n")}
            expectedResult={formData[activeTestCaseIndex].expectedResult}
            onCreate={handleCreate}
            onSave={handleSave}
          />
        )}
      </div>
    </div>
  );
}

export default CreateTicketModal;
