import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CodeRunner from "./CodeRunner";
import { getCodeFiles } from "../../redux/actions";
import axios from "axios";
import {
  DELETE_CODEFILES_URL,
  EDIT_TEST_CASE_URL,
  GET_TESTCASES_BY_ID_URL,
} from "../../utils/URLs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import ConfirmationDeleteModal from "./ConfirmationDeleteModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";
import "./AutomationTable.css";

function AutomationTable({ selecteCodeFiledId, setSelecteCodeFiledId }) {
  const [isEditing, setIsEditing] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] =
    useState(false);
  const [selectedTestCaseId, setSelectedTestCaseId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [output, setOutput] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingCodeFile, setIsLoadingCodeFile] = useState(false);

  const codeFiles = useSelector((state) => state.codeFiles);
  //console.log(codeFiles);
  const dispatch = useDispatch();

  useEffect(() => {
    if (codeFiles.length === 0) {
      setIsLoading(true);
      dispatch(getCodeFiles()).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [dispatch, codeFiles.length]);

  useEffect(() => {
    setOutput(null);
  }, [selecteCodeFiledId]);

  const handleGetTestCaseById = async (test_case_id) => {
    const { data } = await axios.get(
      `${GET_TESTCASES_BY_ID_URL}${test_case_id}`
    );
    return data;
  };

  const handleSelectCodeClick = (id) => {
    if (isEditing) {
      setIsEditing(false);
    }
    setIsLoadingCodeFile(true)
    setSelecteCodeFiledId(id);
  };

  const handleCodeFileDelete = (id, test_case_id) => {
    setSelecteCodeFiledId(id);
    setSelectedTestCaseId(test_case_id);
    setShowDeleteConfirmationModal(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axios.delete(
        `${DELETE_CODEFILES_URL}${selecteCodeFiledId}`
      );
      dispatch(getCodeFiles());
      console.log(response);
      if (response.status === 200) {
        toast.success("CodeFile successfully deleted");
      }
      const testCase = await handleGetTestCaseById(selectedTestCaseId);

      if (testCase) {
        console.log(testCase);
        const updateTestCase = {
          ...testCase,
          is_automated: false,
          codeFileId: "",
        };
        console.log(updateTestCase);
        try {
          await axios.put(
            `${EDIT_TEST_CASE_URL}${selectedTestCaseId}`,
            updateTestCase
          ); //SACAR CUANDO NACHO MODIFIQUE EL BACK, Y EL BACK HAGA ESE PUT
        } catch (error) {
          console.error("Error al obtener el código:", error);
        }
      } else {
        console.log("Test Cases estaba eliminado de antes", testCase);
      }
    } catch (error) {
      console.error("Error al obtener el código:", error);
    } finally {
      setShowDeleteConfirmationModal(false);
    }
  };

  const cancelDelete = () => {
    setShowDeleteConfirmationModal(false);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  return (
    <div className="bg-white shadow-md h-full rounded-xl px-4 dark:bg-inherit dark:border dark:border-gray-700">
      <div className="md:flex">
        <div className="w-full md:w-2/6 pl-10">
          <div className="pb-2 pt-16 mb-4 flex justify-center">
            <input
              className="h-8 px-3 border rounded border-blue-950 focus:border-2 focus:border-blue-950 focus:outline-none dark:border dark:bg-gray-800 dark:border-gray-700"
              type="text"
              id="search"
              name="search"
              value={searchTerm}
              onChange={handleSearchChange}
              placeholder="Search..."
              required
            />
          </div>
          <div className="flex flex-col items-center max-h-96 md:h-3/4 md:max-h-none">
            <div className="flex justify-center">
              <h2 className=" font-bold text-2xl">Test Title</h2>
            </div>
            <div className="overflow-y-auto w-full h-3/4">
              <table className="min-w-full">
                <thead>
                  {/* <tr className="flex justify-center border-b h-12 font-bold dark:border-gray-700">
                    <th className="text-left">Test Case</th>
                  </tr> */}
                </thead>
                <tbody>
                  {isLoading ? (
                    <div className="pt-14 flex justify-center items-center">
                        <FaSpinner className="animate-spin text-3xl text-blue-500" />
                    </div>
                  ) : codeFiles.length === 0 ? (
                    <tr>
                      <td colSpan="2" className="text-center p-2">
                        No Automated Code found. Create your first Automated
                        Code in the Test Automation tab to see them here.
                      </td>
                    </tr>
                  ) : (
                    codeFiles
                      .filter((codeFile) =>
                        codeFile.test_title
                          .toLowerCase()
                          .includes(searchTerm.toLowerCase())
                      )
                      .map((codeFile) => (
                        <tr
                          key={codeFile.id}
                          className={`h-14 border-b hover:bg-gray-200 dark:hover:bg-gray-700 dark:border-gray-700 ${
                            selecteCodeFiledId === codeFile.id
                              ? " bg-gray-100 dark:bg-gray-700"
                              : ""
                          }`}
                        >
                          <td
                            className="px-3 py-1 text-blue-500 underline cursor-pointer"
                            onClick={() => handleSelectCodeClick(codeFile.id)}
                          >
                            {codeFile.test_title}
                          </td>
                          <td className="px-3 py-1 text-right">
                            <button
                              className="text-red-800"
                              onClick={() =>
                                handleCodeFileDelete(
                                  codeFile.id,
                                  codeFile.test_case_id
                                )
                              }
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          </td>
                        </tr>
                      ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className=" w-full md:w-4/6 flex justify-center mt-10">
          <CodeRunner
            codeId={selecteCodeFiledId}
            isEditing={isEditing}
            setIsEditing={setIsEditing}
            output={output}
            setOutput={setOutput}
            isLoadingCodeFile={isLoadingCodeFile}
            setIsLoadingCodeFile={setIsLoadingCodeFile}
          />
        </div>
      </div>

      {showDeleteConfirmationModal && (
        <div>
          <ConfirmationDeleteModal
            isOpen={showDeleteConfirmationModal}
            onConfirm={confirmDelete}
            onCancel={cancelDelete}
          />
        </div>
      )}
    </div>
  );
}

export default AutomationTable;