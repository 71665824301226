import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

function ShowTestCase({ setIsOpen, testCase }) {
  console.log(testCase);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <div className="bg-white border border-solid border-blue-950 h-auto w-3/4 rounded-xl p-8 dark:bg-gray-800 dark:border-gray-700 ">
      <div className="flex justify-end">
        <button className="text-blue-950 dark:text-white" onClick={handleClose}>
          <FontAwesomeIcon icon={faX} />
        </button>
      </div>
      <h1 className="text-blue-950 text-2xl font-bold mb-4 dark:text-white">
        {testCase.test_title}
      </h1>
      <div className="mb-4">
        <h2 className="text-blue-950 text-xl font-bold mb-2 dark:text-white">Related Ticket:</h2>
        <p className="text-gray-700 dark:text-gray-300">{testCase.ticket_id}</p>
      </div>
      <div className="mb-4">
        <h2 className="text-blue-950 text-xl font-bold mb-2 dark:text-white" >Description:</h2>
        <p className="text-gray-700 dark:text-gray-300">{testCase.test_description}</p>
      </div>
      <div className="mb-4">
        <h2 className="text-blue-950 text-xl font-bold mb-2 dark:text-white ">Steps:</h2>
        <ul className="list-disc pl-6 text-gray-700 dark:text-gray-300">
          {testCase.test_steps.map((step, index) => (
            <li key={index}>{step}</li>
          ))}
        </ul>
      </div>
      <div className="mb-4">
        <h2 className="text-blue-950 text-xl font-bold mb-2 dark:text-white">
          Expected Result:
        </h2>
        <p className="text-gray-700 dark:text-gray-300">{testCase.expected_results}</p>
      </div>
      {/* <div className="mb-4">
        <h2 className="text-blue-950 text-xl font-bold mb-2">Estado:</h2>
        <div
          className={`w-32 rounded-full p-2 ${
            testCase.approved
              ? "bg-green-500 text-white px-6"
              : "bg-red-500 text-white  px-6"
          }`}
        >
          {testCase.approved ? "Aprobado" : "No Aprobado"}
        </div>
      </div> */}
    </div>
  );
}

export default ShowTestCase;
