import React, { useEffect, useState } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { DELETE_FEATURE_URL, GET_FEATURES_BY_PROJECT } from "../../utils/URLs";
import { FaSpinner } from "react-icons/fa";
import FeatureForm from "./FeaturesForm";
import DeleteConfirmationModal from "../DeleteConfirmationModal";

function ProjectDetailModal({ projectId, isOpen, onClose }) {
  const [features, setFeatures] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNoFeatures, setHasNoFeatures] = useState(false);
  const [isFormModalOpen, setIsFormModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [featureIdToDelete, setFeatureIdToDelete] = useState(null);

  const fetchFeatures = () => {
    setIsLoading(true);
    axios.get(`${GET_FEATURES_BY_PROJECT}${projectId}`)
      .then((response) => {
        console.log("Data received:", response.data);
        setFeatures(response.data);
        setHasNoFeatures(response.data.length === 0);
      })
      .catch((error) => {
        console.error("Error fetching project features:", error);
        setHasNoFeatures(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (isOpen && projectId) {
      fetchFeatures();
    }
  }, [isOpen, projectId]);

  const toggleFormModal = () => {
    setIsFormModalOpen(!isFormModalOpen);
  };

  const handleDeleteFeature = (featureId, event) => {
    event.stopPropagation();
    setFeatureIdToDelete(featureId);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setFeatureIdToDelete(null);
  };

  const handleDeleteConfirm = () => {
    setIsDeleteModalOpen(false);
    axios.delete(`${DELETE_FEATURE_URL}${featureIdToDelete}`)
      .then(response => {
        console.log("Feature deleted successfully:", response);
        fetchFeatures();
      })
      .catch(error => {
        console.error("Error deleting the feature:", error);
      });
    setFeatureIdToDelete(null);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
      <div className="bg-white border border-solid border-blue-950 h-3/4 w-3/4 rounded-xl p-8 relative dark:bg-gray-800 dark:border-gray-700 overflow-auto">
        <div className="flex justify-end absolute top-0 right-0 p-4">
          <button className="text-blue-950 dark:text-white" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <h2 className="text-blue-950 text-4xl font-bold mb-2 dark:text-white">
          Features
        </h2>
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <FaSpinner className="animate-spin text-4xl text-blue-500" />
          </div>
        ) : hasNoFeatures ? (
          <div className="flex items-center justify-center h-full">
            <p>There are no features for this project.</p>
          </div>
        ) : (
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            {features.map((feature, index) => (
              <div
                key={index}
                className="group relative bg-gray-100 dark:bg-gray-700 p-4 mt-10 md:mt-20 rounded-lg shadow hover:shadow-md transition-shadow duration-200"
              >
                <button
                  onClick={(e) => handleDeleteFeature(feature.id, e)}
                  className="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 text-xs h-5 w-5 bg-white rounded-full shadow-md opacity-0 group-hover:opacity-100 dark:bg-inherit"
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
                <h3 className="text-xl font-semibold dark:text-white">
                  /{feature.name}
                </h3>
              </div>
            ))}
          </div>
        )}
        <button
          onClick={toggleFormModal}
          className="absolute bottom-3 right-3 mb-4 mr-4 bg-blue-500 text-white p-2 w-10 h-10 rounded-full"
        >
          <FontAwesomeIcon icon={faPlus} />
        </button>
      </div>

      {isFormModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <FeatureForm
            onSubmit={() => {
              toggleFormModal();
              fetchFeatures();
            }}
            onClose={toggleFormModal}
            project_id={projectId}
          />
        </div>
      )}

      {isDeleteModalOpen && (
        <DeleteConfirmationModal
          isOpen={isDeleteModalOpen}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
          text={<p>Are you sure you want to <strong>permanently delete</strong> this feature?</p>}
        />
      )}
    </div>
  );
}

export default ProjectDetailModal;
