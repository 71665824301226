import { Link } from 'react-router-dom';
import Footer from "./footer";
import NavBar from "../components/NavBar";

function Home({toggleDarkMode, darkMode}) {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-white dark:bg-gray-800">
      <NavBar toggleDarkMode={toggleDarkMode} darkMode={darkMode} />

      <div className="text-center mt-40 text-blue-800 dark:text-white">
        <h1 className="text-5xl font-mono font-bold ">Welcome to Bugster 🐞</h1>
        <p className="max-w-lg mt-6">
          Explore the efficiency of automated testing with Bugster! Simplify and
          enhance your software testing for a seamless quality experience.
          Welcome to intelligent test automation!
        </p>
        {/* Agregar nueva línea con enlace */}
        <p className="mt-4 max-w-lg text-base">
          New to Bugster? <Link to="/welcome" className="text-sky-700 underline">Click here</Link> to get started!
        </p>
      </div>

      <div className="flex-grow"></div>

      <div className="w-full">
        <Footer />
      </div>
    </div>
  );
}

export default Home;
