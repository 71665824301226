import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXTwitter, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { faPhone, faEnvelope, faLocationDot, faInbox } from '@fortawesome/free-solid-svg-icons';

function Footer() {
  return (
    <div className="bg-blue-800 text-white py-8 mt-40">
      <div className="container mx-auto px-4 flex flex-wrap justify-center items-center text-center">

        {/* Columna de Información */}
        <div className="w-full px-4 mb-6 md:w-1/4 md:mb-0">
          <h3 className="text-lg font-bold mb-4">Information</h3>
          <p><FontAwesomeIcon className="mx-2" icon={faPhone} />+54 351 8845256</p>
          <p><FontAwesomeIcon className="mx-2" icon={faEnvelope} />contact@bugster.com</p>
          <p><FontAwesomeIcon className="ml-2 mr-3" icon={faLocationDot} />New York, EEUU</p>
        </div>

        {/* Columna de Enlaces */}
        <div className="w-full px-4 mb-6 md:w-1/4 md:mb-0">
          <h3 className="text-lg font-bold mb-4">Useful Links</h3>
          <ul>
            <li><Link to="/welcome"><p>About Us</p></Link></li>
            <li><Link to="/*"><p>Past User Experiences</p></Link></li>
          </ul>
        </div>

        {/* Columna de Redes Sociales */}
        <div className="w-full px-4 mb-6 md:w-1/4 md:mb-0">
          <h3 className="text-lg font-bold mb-4">Social Media</h3>
          <ul className="flex justify-center">
            <li className="mx-2 mb-4">
              <a href="https://www.linkedin.com/company/bugsterapp/about/" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className="h-6" icon={faLinkedinIn} />
              </a>
            </li>
            <li className="mx-2">
              <a href="https://twitter.com/Bugsterdotapp" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon className="h-6" icon={faXTwitter} />
              </a>
            </li>
          </ul>
        </div>

        {/* Columna de Formulario de Contacto */}
        <div className="w-full px-4 mb-6 md:w-1/5 md:mb-0">
          <h3 className="text-lg font-bold mb-4">Contact Us</h3>
          <Link to="/contact"><p><FontAwesomeIcon icon={faInbox} /> Contact</p></Link>
        </div>

      </div>
    </div>
  );
}

export default Footer;
