import React from 'react';
import { Link } from 'react-router-dom';
import SingInImagen from "../utils/imagenes/SingUpImagen.png"
import LogoBugster from "../utils/imagenes/LogoBugster.png"
import ImagenOr from "../utils/imagenes/or.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faFacebookF } from '@fortawesome/free-brands-svg-icons';

function SingUp() {
  return (
    <div className="flex h-screen bg-gray-100 items-center justify-center dark:bg-gray-800">

      <div className="w-1/3 p-8 h-screen flex flex-col items-center justify-center">

        <div className="flex mb-4 flex-col w-full">
          <div className="w-full">
            <img src={LogoBugster} alt="Imagen" className="w-16 h-16 mx-auto mb-2" />
            <h2 className="text-3xl text-blue-950 font-semibold text-center dark:text-white">Sign Up</h2>

            <div className="p-2 mt-4 flex">
              <button type="submit" className="bg-blue-800 text-white p-2 mr-2 w-full rounded hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit">
                <FontAwesomeIcon icon={faGoogle} className="mr-2" />Google
              </button>
              {/* className="bg-blue-800 w-32 mr-2 px-2 h-10 border border-blue-800 rounded-full text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit" */}

              <button type="submit" className="bg-blue-800 text-white p-2 ml-2 w-full rounded hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit">
                <FontAwesomeIcon icon={faFacebookF} className="mr-2" />Facebook
              </button>
            </div>
          </div>
        </div>

        <img src={ImagenOr} alt="Or" className="mb-6 w-72 mx-auto"></img>


        <div className="w-full">
          <form>

            <div className="mb-4">
              <label htmlFor="fullname" className="block text-gray-700 text-sm font-bold mb-2 dark:text-white"> Full Name </label>
              <input type="text" id="fullname" name="fullname" className="bg-gray-200 rounded p-2 w-full dark:bg-gray-800 dark:border dark:border-gray-700" placeholder="Name" />
            </div>

            <div className="mb-4">
              <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2 dark:text-white"> Email Address </label>
              <input type="text" id="username" name="username" className="bg-gray-200 rounded p-2 w-full dark:bg-gray-800 dark:border dark:border-gray-700" placeholder="example@gmail.com" />
            </div>

            <div className="mb-4">
              <label htmlFor="username" className="block text-gray-700 text-sm font-bold mb-2 dark:text-white"> Username</label>
              <input type="text" id="username" name="username" className="bg-gray-200 rounded p-2 w-full dark:bg-gray-800 dark:border dark:border-gray-700" placeholder="User name" />
            </div>

            <div className="mb-4">
              <label htmlFor="password" className="block text-gray-700 text-sm font-bold mb-2 dark:text-white"> Password </label>
              <input type="password" id="password" name="password" className="bg-gray-200 rounded p-2 w-full dark:bg-gray-800 dark:border dark:border-gray-700" placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;" />
            </div>

            <div className="flex justify-between text-xs pb-8 dark:text-white">
              <label className="flex">
                <input type="checkbox" name="myCheckbox" className="mr-2" />
                <p>By creating an account you agree to the <a className="text-sky-700 border-b border-sky-700" href="https://www.google.com/?hl=es">terms of use</a> and <a className="text-sky-700 border-b border-sky-700" href="https://www.google.com/?hl=es">privacy policy.</a></p>
              </label>
            </div>

            <button type="submit" className="bg-blue-800 text-white p-2 w-full rounded hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit">
              Sign Up
            </button>

          </form>
        </div>

        <div className="w-72 mx-auto mt-6 text-center text-xs dark:text-white">
          <p>Already have an account? <Link to="/login" className="text-sky-700">Log In</Link></p>
        </div>

      </div>

      <div className="w-2/3 h-screen flex items-center justify-center bg-gray-200 dark:bg-gray-700">
        <div className="w-4/6 h-3/6">
          <img src={SingInImagen} alt="Imagen" className="w-full h-full m-auto" />
        </div>
      </div>

    </div>
  );
}

export default SingUp;
