import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getTickets,
  searchTicketBySummary,
  filterByIssueType,
  filterByPerson,
} from "../../redux/actions";

function TicketsFilterComponent() {
  const dispatch = useDispatch();

  const allTickets = useSelector((state) => state.tickets);

  useEffect(() => {
    dispatch(getTickets());
  }, [dispatch]);

  //Filtro por Issue Type
  function handleFilterByIssueType(event) {
    event.preventDefault();
    dispatch(filterByIssueType(event.target.value));
  }

  //Filtrar por Summary
  const handleSearchBySummary = (event) => {
    event.preventDefault();
    dispatch(searchTicketBySummary(event.target.value));
  };

  //Filtro por Automation Status
  const handleFilterPerson = (event) => {
    event.preventDefault();
    dispatch(filterByPerson(event.target.value));
  };

  function handleReset(event) {
    event.preventDefault();
    dispatch(filterByIssueType("All"));
    dispatch(filterByPerson("All"));
  }

  return (
    <div className="bg-white shadow-md rounded-xl h-auto p-4 mb-6 flex flex-wrap gap-4 justify-evenly dark:bg-gray-800 dark:text-white dark:border dark:border-gray-700">
      <div className=" flex flex-col w-full md:w-auto">
        <label htmlFor="nameFilter" className=" text-sm">
          Summary:
        </label>
        <input
          id="nameFilter"
          className=" w-full md:w-48 p-1 border-2 border-blue-950 rounded-md dark:border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
          placeholder="Ticket summary..."
          type="text"
          onChange={(event) => handleSearchBySummary(event)}
        />
      </div>

      <div className="filter-group flex flex-col w-full md:w-auto">
        <label htmlFor="ticketFilter" className=" text-sm">
          Issue Type:
        </label>
        <select
          id="ticketFilter"
          className=" w-full md:w-48 p-1 border-2 border-blue-950 rounded-md dark:border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
          onChange={(event) => handleFilterByIssueType(event)}
          defaultValue="All"
        >
          <option value="All">All</option>
          {Array.from(
            new Set(allTickets.map((ticket) => ticket.fields.issuetype.name))
          ).map((uniqueIssueType, index) => (
            <option value={uniqueIssueType} key={index}>
              {uniqueIssueType}
            </option>
          ))}
        </select>
      </div>

      <div className="filter-group flex flex-col w-full md:w-auto">
        <label htmlFor="automationStatusFilter" className=" text-sm">
          Assignee:
        </label>
        <select
          id="automationStatusFilter"
          className=" w-full md:w-48 p-1 border-2 border-blue-950 rounded-md dark:border dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400"
          onChange={(event) => handleFilterPerson(event)}
          defaultValue="All"
        >
          <option value="All">All</option>
          {Array.from(
            new Set(
              allTickets.map((ticket) => ticket.fields.assignee?.displayName)
            )
          ).map((uniqueName, index) => (
            <option value={uniqueName} key={index}>
              {uniqueName ? uniqueName : "Unassigned"}
            </option>
          ))}
        </select>
      </div>

      <button
        className="bg-blue-800 px-2 mt-2 h-10 border border-blue-800 rounded-md text-sm text-white hover:bg-inherit hover:text-blue-800 hover:border hover:border-blue-800 hover:text-sm dark:hover:bg-inherit"
        onClick={(event) => handleReset(event)}
      >
        Reset Filters
      </button>
    </div>
  );
}

export default TicketsFilterComponent;
