import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { getTestCases } from "../../redux/actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import EditTestCase from "./EditTestCase";
import ShowTestCase from "./ShowTestCase";
import DeleteConfirmationModal from "../DeleteConfirmationModal";
import { DELETE_TEST_CASES_URL } from "../../utils/URLs";
import { CODE_CREATION } from "../../utils/URLs";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaSpinner } from "react-icons/fa";

function TableTestCasesComponent({ handleOptionFromTestCaseM }) {
  const [isEditTestCaseModalOpen, setIsEditTestCaseModalOpen] = useState(false);
  const [isShowTestCaseModalOpen, setIsShowTestCaseModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [deleteCandidateId, setDeleteCandidateId] = useState(null);
  const [editTestCaseData, setEditTestCaseData] = useState(null);
  const [showTestCaseData, setShowTestCaseData] = useState(null);
  const [loadingByRow, setLoadingByRow] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const testCases = useSelector((state) => state.filteredTestCases);
  console.log(testCases);

  const dispatch = useDispatch();

  useEffect(() => {
    if (testCases.length === 0) {
      setIsLoading(true);
      dispatch(getTestCases()).then(() => setIsLoading(false));
    } else {
      setIsLoading(false);
    }
  }, [dispatch, testCases.length]);

  const handleAutomateClick = async (testCase) => {
    console.log(testCase);
    const testCaseReform = {
      id: testCase.id,
      ticketId: testCase.ticket_id,
      testCaseNumber: 0,
      testTitle: testCase.test_title,
      testDescription: testCase.test_description,
      testSteps: testCase.test_steps,
      expectedResults: testCase.expected_results,
      approved: testCase.approved,
      isEdited: testCase.is_edited,
      isAutomated: testCase.is_automated,
    };
    console.log(testCaseReform);
    setLoadingByRow((prevLoadingByRow) => ({
      ...prevLoadingByRow,
      [testCase.id]: true,
    }));

    try {
      const response = await axios.post(CODE_CREATION, {
        test_case: testCaseReform,
      });
      const { data } = response;
      console.log(data);
      handleUpdateTestCases();
      toast.success("Code created successfully!");
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingByRow((prevLoadingByRow) => ({
        ...prevLoadingByRow,
        [testCase.id]: false,
      }));
    }
  };

  const handleEditClick = async (testCase) => {
    setIsEditTestCaseModalOpen(true);
    setEditTestCaseData(testCase);
  };

  const handleShowClick = async (testCase) => {
    setIsShowTestCaseModalOpen(true);
    setShowTestCaseData(testCase);
  };

  const handleUpdateTestCases = () => {
    dispatch(getTestCases());
  };

  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(`${DELETE_TEST_CASES_URL}${id}`);
      console.log(response);
      toast.success("Test Case successfully deleted!");
      handleUpdateTestCases();
    } catch (error) {
      console.error("Error al enviar la solicitud DELETE:", error);
    }
  };

  const handleDeleteClick = (id) => {
    setDeleteCandidateId(id);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteCancel = () => {
    setIsDeleteModalOpen(false);
    setDeleteCandidateId(null);
  };

  const handleDeleteConfirm = async () => {
    if (deleteCandidateId) {
      await handleDelete(deleteCandidateId);
      setIsDeleteModalOpen(false);
      setDeleteCandidateId(null);
    }
  };

  const handleOpenSandboxClick = async (codefile_id) => {
    console.log(codefile_id);
    handleOptionFromTestCaseM("Test Automation", codefile_id);
  };

  return (
    <div>
      <div className="flex flex-col gap-2  bg-white shadow-md rounded-xl dark:bg-gray-800 dark:text-white dark:border dark:border-gray-700">
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b h-12 font-bold dark:border-gray-700">
              <th className=" w-1/12 text-left p-2">ID</th>
              <th className=" w-4/12 text-left p-2">Title</th>
              <th className=" w-2/12 text-left p-2">Related Ticket</th>
              {/* <th className=" w-2/12 text-left p-2">Created By</th> */}
              {/* HASTA QUE TENGAMOS CRUD USUARIOS, cambiar tamano de las filas al agregar nuevamente */}
              <th className=" w-2/12 text-left p-2">Automation Status</th>
              <th className=" w-2/12 text-left p-2">Actions</th>
              <th className=" w-1/12 text-left p-2"></th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="100%">
                  <div className="flex justify-center items-center py-14">
                    <FaSpinner className="animate-spin text-3xl text-blue-500" />
                  </div>
                </td>
              </tr>
            ) : testCases.length > 0 ? (
              testCases.map((testCase, index) => (
                <tr
                  key={index}
                  className=" h-14 border-b hover:bg-gray-200 dark:hover:bg-gray-700 dark:border-gray-700"
                >
                  <td className="font-semibold text-xs p-2">
                    TC-{testCase.id}
                  </td>
                  <td className="p-2">
                    <p
                      onClick={() => handleShowClick(testCase)}
                      className="text-blue-500 underline cursor-pointer"
                    >
                      {testCase.test_title}
                    </p>
                  </td>
                  <td className="p-2">{testCase.ticket_id}</td>
                  {/* <td className="p-2">Name</td> */}
                  {/* HASTA QUE TENGAMOS CRUD USUARIOS */}
                  <td className=" p-2 text-center text-base">
                    <div
                      className={` w-32 rounded-lg p-1${
                        testCase.is_automated
                          ? " text-green-600"
                          : " text-red-400 "
                      }`}
                    >
                      {testCase.is_automated ? "Automated" : "Not Automated"}
                    </div>
                  </td>
                  <td className="p-2">
                    {testCase.is_automated ? (
                      <button
                        className={` ${
                          loadingByRow[testCase.id]
                            ? ""
                            : "border border-green-600"
                        } text-${
                          loadingByRow[testCase.id] ? "blue-500" : "green-600"
                        } rounded-lg px-2 py-1`}
                        onClick={() =>
                          loadingByRow[testCase.id]
                            ? null
                            : handleOpenSandboxClick(testCase.codefile_id)
                        }
                      >
                        {loadingByRow[testCase.id] ? (
                          <div className="flex justify-center mx-10">
                            <FaSpinner className="animate-spin" />
                          </div>
                        ) : (
                          "Open Sandbox"
                        )}
                      </button>
                    ) : (
                      <button
                        className={`${
                          loadingByRow[testCase.id]
                            ? ""
                            : "border border-red-400"
                        } text-${
                          loadingByRow[testCase.id] ? "blue-500" : "red-400"
                        } rounded-lg px-3 py-1`}
                        onClick={() =>
                          loadingByRow[testCase.id]
                            ? null
                            : handleAutomateClick(testCase)
                        }
                      >
                        {loadingByRow[testCase.id] ? (
                          <div className="flex justify-center mx-10">
                            <FaSpinner className="animate-spin" />
                          </div>
                        ) : (
                          "Automate TCs"
                        )}
                      </button>
                    )}
                  </td>
                  <td>
                    <div className="flex justify-center">
                      <button
                        onClick={() => handleEditClick(testCase)}
                        className="text-blue-950 mr-2 dark:text-blue-500"
                      >
                        <FontAwesomeIcon icon={faPenToSquare} />
                      </button>
                      <button
                        type="button"
                        onClick={() => handleDeleteClick(testCase.id)}
                        className="text-red-800 ml-2"
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </button>
                    </div>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5">
                  <div className="flex flex-col justify-center items-center my-10">
                    <p className="text-center font-medium text-lg">
                      Create your first Test Cases in the Tickets tab to see them visualized here.
                    </p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

        {isEditTestCaseModalOpen && editTestCaseData && (
          <div
            className={`fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-md ${
              isEditTestCaseModalOpen ? "block" : "hidden"
            }`}
          >
            <EditTestCase
              testCase={editTestCaseData}
              setIsOpen={setIsEditTestCaseModalOpen}
              handleUpdateTestCases={handleUpdateTestCases}
            />
          </div>
        )}

        {isShowTestCaseModalOpen && showTestCaseData && (
          <div
            className={`fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-md ${
              isShowTestCaseModalOpen ? "block" : "hidden"
            }`}
          >
            <ShowTestCase
              testCase={showTestCaseData}
              setIsOpen={setIsShowTestCaseModalOpen}
            />
          </div>
        )}

        {isDeleteModalOpen && (
          <div>
            <DeleteConfirmationModal
              isOpen={isDeleteModalOpen}
              onCancel={handleDeleteCancel}
              onConfirm={handleDeleteConfirm}
              text={<p>Are you sure you want to <strong>permanently delete</strong> this TestCase? <br></br> Please be aware that this action will also <strong>permanently delete the associated automated code</strong>.</p>}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default TableTestCasesComponent;
