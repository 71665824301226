// URL del servidor
export const API_BASE_URL = process.env.REACT_APP_URL_BASE_DB_LAYER;
export const API_BASE_URL_SERVER = process.env.REACT_APP_URL_BASE_SERVER;
export const API_ORCHESTRATOR = process.env.REACT_APP_URL_BASE_ORCHESTRATOR;

//CRUD Projects
export const POST_PROJECTS_URL = `${API_BASE_URL}/projects`
export const GET_PROJECTS_URL = `${API_BASE_URL}/projects`
export const DELETE_PROJECTS_URL = `${API_BASE_URL}/projects`//ID

//CRUD Tickets:
export const GET_TICKETS_URL = `${API_BASE_URL_SERVER}/ops/tickets?board_number=1`//El 1 deberia ser dinamico//AI SERVER

//CRUD Test Cases:
export const GET_TESTCASES_URL = `${API_BASE_URL}/testcases`
export const GET_TESTCASES_BY_ID_URL = `${API_BASE_URL}/testcases/`
export const POST_TEST_CASES_URL = `${API_BASE_URL}/testcases`;
export const DELETE_TEST_CASES_URL = `${API_BASE_URL}/testcases/`;
export const EDIT_TEST_CASE_URL = `${API_BASE_URL}/testcases/`;
export const TC_GENERATION_URL = `${API_BASE_URL_SERVER}/tcgeneration`;//AI SERVER


//CRUD Code Atomation
export const CODE_CREATION = `${API_BASE_URL_SERVER}/codecreation`;//AI SERVER
export const GET_CODEFILES_URL = `${API_BASE_URL}/codefiles`;
export const GET_CODEFILES_BY_ID_URL = `${API_BASE_URL}/codefiles/`;//ID
export const PUT_CODEFILES_URL = `${API_BASE_URL}/codefiles/`;//ID
export const DELETE_CODEFILES_URL = `${API_BASE_URL}/codefiles/`//ID
export const POST_CODEFILES_URL = `${API_BASE_URL}/codefiles`


//CRUD RunGroup
export const POST_RUN_GROUP = `${API_BASE_URL}/run-groups`
export const GET_RUN_GROUPS_URL = `${API_BASE_URL}/run-groups`

//CRUD TestRunGroups 
export const GET_TEST_RUN_GROUPS = `${API_BASE_URL}/test-run-groups/by-run-group/`//ID

//API ORCHESTRATOR 
export const SINGLE_EXECUTION = `${API_ORCHESTRATOR}/single/sandbox-execution?test_case_id=`

//FEATURES by PROJECT
export const GET_FEATURES_BY_PROJECT = `${API_BASE_URL}/features/by-project/`//IDPROJECT
export const POST_NEW_FEATURES = `${API_BASE_URL}/features`
export const DELETE_FEATURE_URL = `${API_BASE_URL}/features`//ID

