import React, { useState, useEffect } from "react";

const TestCaseWindow = ({
  testTitle,
  description,
  steps,
  expectedResult,
  onCreate,
  onSave
}) => {
  
  const [isEditable, setIsEditable] = useState(false);
  const [editedTitle, setEditedTitle] = useState(testTitle);
  const [editedDescription, setEditedDescription] = useState(description);
  const [editedSteps, setEditedSteps] = useState(steps);
  const [editedexpected_results, setEditedexpected_results] =
    useState(expectedResult);

  useEffect(() => {
    setEditedTitle(testTitle);
    setEditedDescription(description);
    setEditedSteps(steps);
    setEditedexpected_results(expectedResult);
  }, [testTitle, description, steps, expectedResult]);

  const handleEdit = () => {
    setIsEditable((prev) => !prev);
  };

  const handleSave = () => {
    setIsEditable(false);
    onSave({
      testTitle: editedTitle,
      description: editedDescription,
      steps: editedSteps.split("\n"),
      expectedResult: editedexpected_results,
    });
  };

  const handleCreate = () => {
    onCreate();
  };

  return (
    <div className="border border-gray-300 rounded p-4 my-4 bg-white dark:bg-gray-800 dark:border-gray-700">
      {isEditable ? (
        <>
          <input
            type="text"
            value={editedTitle}
            onChange={(e) => setEditedTitle(e.target.value)}
            className="border border-gray-300 rounded w-full p-2 mb-2 dark:bg-gray-800 dark:border-gray-700"
            maxLength="100"
          />
          <textarea
            value={editedDescription}
            onChange={(e) => setEditedDescription(e.target.value)}
            className="border border-gray-300 rounded w-full p-2 mb-2 h-16 dark:bg-gray-800 dark:border-gray-700"
          />
          <textarea
            value={editedSteps}
            onChange={(e) => setEditedSteps(e.target.value)}
            className="border border-gray-300 rounded w-full p-2 mb-2 h-32 dark:bg-gray-800 dark:border-gray-700"
          />
          <textarea
            value={editedexpected_results}
            onChange={(e) => setEditedexpected_results(e.target.value)}
            className="border border-gray-300 rounded w-full p-2 mb-2 h-16 dark:bg-gray-800 dark:border-gray-700"
          />
          <div className="text-right mt-4">
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded mr-2"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="bg-red-500 px-4 py-2 rounded"
              onClick={handleEdit}
            >
              Cancel
            </button>
          </div>
        </>
      ) : (
        <>
          <div>
            <h4 className="text-lg font-semibold">Title</h4>
            <p>{editedTitle}</p>
          </div>
          <div className="mt-2">
            <h4 className="text-lg font-semibold">Description</h4>
            <p>{editedDescription}</p>
          </div>
          <div className="mt-2">
            <h4 className="text-lg font-semibold">Steps</h4>
            <ul>
              {editedSteps.split("\n").map((item, index) => (
                <li key={index}>{`${index + 1}. ${item}`}</li>
              ))}
            </ul>
          </div>
          <div className="mt-2">
            <h4 className="text-lg font-semibold">Expected Result</h4>
            <p>{editedexpected_results}</p>
          </div>
          <div className="text-right mt-4">
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded mr-2"
              onClick={handleEdit}
            >
              Edit
            </button>
            <button
              className="bg-blue-900 text-white px-4 py-2 rounded"
              onClick={handleCreate}
            >
              Create
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default TestCaseWindow;
