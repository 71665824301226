import React, { useState } from "react";
import LoginPopup from "../components/loginPopup";
import SideBar from "../components/SideBar";
import TableTicketsComponent from "../components/DashboardTickets/TableTicketsComponent";
import TableTestCasesComponent from "../components/TestCases/TestCaseManagment";
import TestCasesFilterComponent from "../components/TestCases/TestCasesFilterComponent";
import TicketsFilterComponent from "../components/DashboardTickets/TicketsFilterComponent";
import AutomationTable from "../components/Automation/AutomationTable";
import TableRunsAndResults from "../components/RunsAndResult/TableRunsAndResults";
//import FilterRunsAndResults from "../components/RunsAndResult/FilterRunsAndResults";
import Settings from "../components/Settings/Settings";
import TestRunDetail from "../components/RunsAndResult/TestRunDetail";
import ProjectsManagment from "../components/Projects/ProjectsManagment";

function DashboardUsuario({ toggleDarkMode, darkMode }) {
  const [authenticated, setAuthenticated] = useState(true); //CAMBIAR PARA PEDIR CONTRASEÑA
  const [selectedOption, setSelectedOption] = useState("Tasks");
  const [selecteCodeFiledId, setSelecteCodeFiledId] = useState(null);
  const [testRunDetailOpen, setTestRunDetailOpen] = useState(false);
  const [selectedRunGroupId, setSelectedRunGroupId] = useState(null);

  const handleOptionFromTestCaseM = (option, codefile_id) => {
    setSelectedOption(option);
    setSelecteCodeFiledId(codefile_id);
    console.log(codefile_id);
  };

  return (
    <div className="flex bg-gray-100 h-full min-h-screen pb-10 pr-6 text-blue-950 dark:bg-gray-800 dark:text-white">
      {!authenticated ? (
        <LoginPopup
          setAuthenticated={setAuthenticated}
          authenticated={authenticated}
        />
      ) : (
        <div className="flex flex-col md:flex-row h-full w-full">
          <SideBar
            setSelectedOption={setSelectedOption}
            selected={selectedOption}
          />
          <div className="flex-grow">
            <div className={`pl-6 pr-1 md:px-0 w-full`}>
              {selectedOption === "Projects" ? (
                <div>
                  <h2 className=" text-4xl p-8 font-bold ">Projects</h2>
                  <ProjectsManagment></ProjectsManagment>
                </div>
              ) : selectedOption === "Tasks" ? (
                <div>
                  <h2 className=" text-4xl p-8 font-bold ">Tasks</h2>
                  <TicketsFilterComponent></TicketsFilterComponent>
                  <TableTicketsComponent></TableTicketsComponent>
                </div>
              ) : selectedOption === "TestCase Management" ? (
                <div>
                  <h2 className=" text-4xl p-8 font-bold ">
                    Test Case Management
                  </h2>
                  <TestCasesFilterComponent></TestCasesFilterComponent>
                  <TableTestCasesComponent
                    handleOptionFromTestCaseM={handleOptionFromTestCaseM}
                  ></TableTestCasesComponent>
                </div>
              ) : selectedOption === "Test Automation" ? (
                <div>
                  <h2 className=" text-4xl p-8 font-bold ">Test Automation</h2>
                  <AutomationTable
                    selecteCodeFiledId={selecteCodeFiledId}
                    setSelecteCodeFiledId={setSelecteCodeFiledId}
                  ></AutomationTable>
                </div>
              ) : selectedOption === "Runs and Results" ? (
                <div>
                  {testRunDetailOpen ? (
                    <div>
                      <h2 className=" text-4xl p-8 font-bold ">
                        Test Run Detail
                      </h2>
                      <TestRunDetail
                        setTestRunDetailOpen={setTestRunDetailOpen}
                        selectedRunGroupId={selectedRunGroupId}
                      ></TestRunDetail>
                    </div>
                  ) : (
                    <div>
                      <h2 className=" text-4xl p-8 font-bold ">
                        Runs and Results
                      </h2>
                      {/* <FilterRunsAndResults></FilterRunsAndResults> */}
                      <TableRunsAndResults
                        setTestRunDetailOpen={setTestRunDetailOpen}
                        setSelectedRunGroupId={setSelectedRunGroupId}
                      ></TableRunsAndResults>
                    </div>
                  )}
                </div>
              ) : selectedOption === "Settings" ? (
                <div>
                  <h2 className=" text-4xl p-8 font-bold ">Settings</h2>
                  <Settings
                    toggleDarkMode={toggleDarkMode}
                    darkMode={darkMode}
                  ></Settings>
                </div>
              ) : (
                <div className="flex items-center justify-center mt-20">
                  <h2 className=" text-4xl p-8 font-bold ">{selectedOption}</h2>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default DashboardUsuario;
