import React from 'react';

const SaveConfirmationModal = ({ isOpen, onCancel, onConfirm }) => {
    return (
      <div className={`fixed top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-md ${isOpen ? "block" : "hidden"} `} style={{ zIndex: 1000 }}>
        <div className="bg-white p-8 rounded-md shadow-md border border-blue-950 dark:bg-gray-800 dark:border-gray-700">
          <p className="mb-4 text-lg">Are you sure you want to edit this code?</p>
          <div className="flex justify-end">
            <button className="mr-2 bg-gray-300 px-4 py-2 rounded-md" onClick={onCancel}>Cancel</button>
            <button className="bg-green-500 text-white px-4 py-2 rounded-md" onClick={onConfirm}>Save</button>
          </div>
        </div>
      </div>
    );
  };

export default SaveConfirmationModal;

  